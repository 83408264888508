import React from 'react'
import { Transition } from '@tailwindui/react'
import { PermitedOrderStatus } from '../../../core/models/__generated__/globalTypes'

const contentByStatus = {
  [PermitedOrderStatus.VALIDATED]: {
    title: 'Confirmer la prise en charge',
    body:
      'Êtes-vous sûr(e) de vouloir confirmer la prise en charge de la commande ? Un mail sera automatiquement envoyé au client pour le prévenir.',
  },
  [PermitedOrderStatus.PREPARED]: {
    title: 'Confirmer que la commande est prête',
    body:
      'Êtes-vous sûr(e) de vouloir confirmer que la commande est prête ? Un mail sera automatiquement envoyé au client pour le prévenir.',
  },
  [PermitedOrderStatus.DELIVERED]: {
    title: 'Confirmer la livraison de la commande',
    body:
      "Êtes-vous sûr(e) de vouloir confirmer la livraison de la commande ? Un mail lui sera automatiquement envoyé pour le remercier de son achat. Aussi, n'oubliez pas de créer dans Wino la facture et le bon de livraison correspondants à cette commande !",
  },
  [PermitedOrderStatus.CANCELED]: {
    title: "Confirmer l'annulation",
    body:
      "Êtes-vous sûr(e) de vouloir confirmer l'annulation de la commande ? Un mail sera automatiquement envoyé au client pour lui confirmer l'annulation. Une fois la commande annulée, vous serez chargé(e) de rembourser le client. Vous pouvez nous contacter sur le support à ce sujet si besoin.",
  },
  [PermitedOrderStatus.REFUNDED]: {
    title: 'Confirmer le remboursement',
    body:
      'Êtes-vous sûr(e) de vouloir confirmer le remboursement de la commande ? Un mail sera automatiquement envoyé au client pour lui confirmer le remboursement.',
  },
}

export default function ChangeStatusModal(props: ChangeStatusModalProps) {
  const { open, status, onCancel, onValidate } = props

  const title = contentByStatus[status].title
  const body = contentByStatus[status].body

  return (
    <Transition
      show={open}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => onCancel()}
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{body}</p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                onClick={() => onValidate()}
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Confirmer
              </button>
              <button
                onClick={() => onCancel()}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

interface ChangeStatusModalProps {
  open: boolean
  status: PermitedOrderStatus
  onValidate: () => void
  onCancel: () => void
}
