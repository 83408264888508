import React, { useLayoutEffect, useState } from 'react'
import { useAlerts } from '../../core/contexts/alerts'
import {
  useActivateShop,
  useCurrentShop,
  useDeactivateShop,
  useUpdateShop,
  useUpdateShopHomeDelivery,
  useUpdateShopImages,
  useUpdateShopOrderSettings,
} from '../../core/models/shop/hooks'
import Container from '../../lib/components/layout/Container'
import Loading from '../../lib/components/layout/Loading'
import SettingsLayout from '../../lib/containers/layout/SettingsLayout'
import PublicInformationsForm, {
  FormSchema as PublicInformationSchema,
} from '../../lib/containers/shops/PublicInformationsForm'
import { UpdateShopInput } from '../../core/models/__generated__/globalTypes'
import WebsiteSettingsForm from '../../lib/containers/shops/WebsiteSettingsForm'
import TermsOfSaleForm from '../../lib/containers/shops/TermsOfSaleForm'
import DeliverySettingsForm from '../../lib/containers/shops/DeliverySettingsForm'
import OrderSettingsForm from '../../lib/containers/shops/OrderSettingsForm'
import useQueryParams from '../../core/hooks/useQueryParams'

enum SettingsPages {
  PublicInformations = 'PUBLIC_INFORMATIONS',
  WebsiteSettings = 'WEBSITE_SETTINGS',
  TermsOfSale = 'TERMS_OF_SALE',
  DeliverySettings = 'DELIVERY_SETTINGS',
  OrderSettings = 'ORDER_SETTINGS',
}

const pages = {
  [SettingsPages.PublicInformations]: {
    component: PublicInformationsForm,
    icon: (
      <svg
        className="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
        />
      </svg>
    ),
    title: 'Informations publiques',
  },
  [SettingsPages.WebsiteSettings]: {
    component: WebsiteSettingsForm,
    icon: (
      <svg
        className="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
        />
      </svg>
    ),
    title: 'Statut de la boutique',
  },
  [SettingsPages.DeliverySettings]: {
    component: DeliverySettingsForm,
    icon: (
      <svg
        className="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          fill="#fff"
          d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
        />
      </svg>
    ),
    title: 'Conditions de livraison',
  },
  [SettingsPages.TermsOfSale]: {
    component: TermsOfSaleForm,
    icon: (
      <svg
        className="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
        />
      </svg>
    ),
    title: 'Conditions de vente',
  },
  [SettingsPages.OrderSettings]: {
    component: OrderSettingsForm,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
        />
      </svg>
    ),
    title: 'Paramètres de commande',
  },
}

export default function ShopSettingsPage() {
  const { pushSuccessAlert, pushErrorAlert } = useAlerts()
  const { payment_activation } = useQueryParams(['payment_activation'])
  const [currentPage, setCurrentPage] = useState(
    payment_activation ? SettingsPages.WebsiteSettings : SettingsPages.PublicInformations,
  )
  const { shop, loading } = useCurrentShop()
  const { updateShop } = useUpdateShop({
    onCompleted: () => pushSuccessAlert('Vos modifications sont enregistrées'),
    onError: () =>
      pushErrorAlert("Une erreur s'est produite, essayez d'enregistrer à nouveau"),
  })
  const { activateShop } = useActivateShop({
    onCompleted: () =>
      pushSuccessAlert('Votre boutique est désormais accessible au public'),
    onError: () =>
      pushErrorAlert("Une erreur s'est produite, essayez à nouveau ou contactez-nous"),
  })
  const { deactivateShop } = useDeactivateShop({
    onCompleted: () => pushSuccessAlert('Votre boutique est maintenant désactivée'),
    onError: () =>
      pushErrorAlert("Une erreur s'est produite, essayez à nouveau ou contactez-nous"),
  })
  const { updateShopImages } = useUpdateShopImages({
    onCompleted: () => pushSuccessAlert('Vos modifications sont enregistrées'),
    onError: () =>
      pushErrorAlert("Une erreur s'est produite, essayez d'enregistrer à nouveau"),
  })
  const { updateShopHomeDelivery } = useUpdateShopHomeDelivery({
    onCompleted: () => pushSuccessAlert('Vos modifications sont enregistrées'),
    onError: () =>
      pushErrorAlert("Une erreur s'est produite, essayez d'enregistrer à nouveau"),
  })
  const { updateShopOrderSettings } = useUpdateShopOrderSettings({
    onCompleted: () => pushSuccessAlert('Vos modifications sont enregistrées'),
    onError: () =>
      pushErrorAlert("Une erreur s'est produite, essayez d'enregistrer à nouveau"),
  })

  function onUpdatePublicInformations(data: PublicInformationSchema) {
    const input: Partial<PublicInformationSchema> & Pick<UpdateShopInput, 'address'> = {
      ...data,
      address: {
        street: data.addressStreet,
        city: data.addressCity,
        postCode: data.addressPostalCode,
      },
    }
    delete input.addressStreet
    delete input.addressCity
    delete input.addressPostalCode

    updateShop({ id: shop?.id as string, input })
    setCurrentPage(SettingsPages.PublicInformations)
  }

  function onActivateShop() {
    activateShop({ id: shop?.id as string })
    setCurrentPage(SettingsPages.WebsiteSettings)
  }

  function onDeactivateShop() {
    deactivateShop({ id: shop?.id as string })
    setCurrentPage(SettingsPages.WebsiteSettings)
  }

  function onUpdateTermsOfSale(data: string) {
    updateShop({ id: shop?.id as string, input: { termsOfSale: data } })
    setCurrentPage(SettingsPages.TermsOfSale)
  }

  function onUpdateShopImages(data: { uri?: string; file?: File }[]) {
    updateShopImages({ id: shop?.id as string, input: data })
    setCurrentPage(SettingsPages.PublicInformations)
  }

  function onActivateShopHomeDelivery() {
    onUpdateShopHomeDelivery({ active: true })
  }

  function onDeactivateShopHomeDelivery() {
    onUpdateShopHomeDelivery({ active: false })
  }

  function onUpdateShopHomeDelivery(data: {
    price?: number
    minDaysDelay?: number
    maxDaysDelay?: number
    active?: boolean
  }) {
    updateShopHomeDelivery({
      id: shop?.id as string,
      input: {
        price: shop?.homeDelivery.price as number,
        minDaysDelay: shop?.homeDelivery.minDaysDelay as number,
        maxDaysDelay: shop?.homeDelivery.maxDaysDelay as number,
        active: shop?.homeDelivery.active ?? false,
        ...data,
      },
    })
    setCurrentPage(SettingsPages.DeliverySettings)
  }

  function onUpdateShopOrderSettings(data: { allowOutOfStockProducts: boolean }) {
    updateShopOrderSettings({
      id: shop?.id as string,
      input: {
        ...data,
      },
    })
    setCurrentPage(SettingsPages.OrderSettings)
  }

  useLayoutEffect(() => {
    if (payment_activation === '"success"') {
      pushSuccessAlert(' Le compte de paiement est activé')
    } else if (payment_activation === '"failed"') {
      pushErrorAlert('La création de votre compte de paiement a échoué')
    }
  }, [payment_activation])

  if (loading || typeof shop !== 'object') {
    return <Loading />
  }

  return (
    <Container>
      <h1 className="pt-5 pb-3 text-2xl font-semibold text-gray-900">Paramètres</h1>
      <SettingsLayout
        pages={pages}
        menu={[
          SettingsPages.PublicInformations,
          SettingsPages.WebsiteSettings,
          SettingsPages.DeliverySettings,
          SettingsPages.TermsOfSale,
          SettingsPages.OrderSettings,
        ]}
        initialPage={currentPage}
        pageProps={{
          shop,
          onUpdatePublicInformations,
          onUpdateShopImages,
          onActivateShop,
          onDeactivateShop,
          onUpdateTermsOfSale,
          onUpdateShopHomeDelivery,
          onActivateShopHomeDelivery,
          onDeactivateShopHomeDelivery,
          onUpdateShopOrderSettings,
        }}
      />
    </Container>
  )
}
