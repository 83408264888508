import React, { useRef } from 'react'
import { Transition } from '@tailwindui/react'
import { useClickOutside } from '../../../core/hooks/useClickOutside'

export default function DropDown(props: DropDownProps) {
  const { open = false, children, onClose = () => {} } = props
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, onClose, [])

  return (
    <Transition
      show={open}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div
        ref={ref}
        className="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mb-1 ml-2"
      >
        {children}
      </div>
    </Transition>
  )
}

interface DropDownProps {
  open?: boolean
  onClose?: () => void
  children: React.ReactNode
}
