import React from 'react'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'
import { isDate } from 'date-fns'

import StringField from '../../components/fields/StringField'
import SelectField from '../../components/fields/SelectField'
import NumberField from '../../components/fields/NumberField'
import Button from '../../components/buttons/Button'
import DatePickerField from '../../components/fields/DatePickerField'
import { GetDiscount_discount } from '../../../core/models/discount/__generated__/GetDiscount'
import { activeStates, computeBorderDate } from '../../../core/models/discount/utils'
import {
  CreateDiscountInput,
  DiscountUnit,
  UpdateDiscountInput,
} from '../../../core/models/__generated__/globalTypes'
import { Maybe } from '../../../core/types'
import { discountUnits } from '../../../core/models/discount/utils'

type FormSchema = {
  unit: string
  active: boolean
  couponCode: string
  minimumOrderValue: string
  value: string
  validFrom: Date
  validUntil?: Maybe<Date>
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FormSchema = Yup.object().shape<FormSchema>({
  couponCode: Yup.string().required('Ce champ est requis').min(3, 'Minimum 3 caractères'),
  unit: Yup.mixed<string>().oneOf(
    discountUnits.map((unit) => unit.value),
    'Ce champ est requis',
  ),
  value: Yup.string()
    .required('Ce champ est requis')
    .test({
      name: 'value',
      exclusive: true,
      params: {},
      message: 'Valeur non valide',
      test: function (value) {
        if (value === null || value === undefined || Number(value) < 0) {
          return true
        } else if (
          value &&
          this.parent.unit === DiscountUnit.EURO &&
          this.parent.minimumOrderValue &&
          Number(value) > Number(this.parent.minimumOrderValue)
        ) {
          return this.createError({
            message: `La valeur doit être inferieur aux panier minimum`,
            path: 'value',
          })
        } else if (
          value &&
          this.parent.unit === DiscountUnit.PERCENTAGE &&
          (Number(value) < 0 || Number(value) > 100)
        ) {
          return this.createError({
            message: `Le pourcentage doit être entre 0 et 100`,
            path: 'value',
          })
        } else return true
      },
    }),
  minimumOrderValue: Yup.string()
    .required('Ce champ est requis')
    .test('valid-price', 'Valeur non valide', (value) => Number(value) > 0),
  active: Yup.boolean()
    .oneOf(
      activeStates.map((state) => state.value),
      'Ce champ est requis',
    )
    .required('Ce champ est requis'),
  validFrom: Yup.date()
    .typeError('Ce champ est requis')
    .required()
    .test('valid-from', 'Valeur non valide', (value) => isDate(value)),
})

export default function DiscountForm(props: DiscountFormProps) {
  const { discount, onSave } = props

  const initialValues = {
    unit: discount?.unit ?? DiscountUnit.EURO,
    couponCode: discount?.couponCode ?? '',
    value: discount?.value ? String(discount?.value) : '',
    minimumOrderValue: discount?.minimumOrderValue
      ? String(discount?.minimumOrderValue)
      : '',
    validFrom: discount?.validFrom ?? '',
    validUntil: discount?.validUntil ?? '',
    active: discount?.active ?? false,
  }

  const history = useHistory()
  const { register, watch, handleSubmit, errors, control } = useForm<FormSchema>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: initialValues,
  })

  const { unit, couponCode, validFrom, validUntil, active } = watch([
    'unit',
    'couponCode',
    'value',
    'minimumOrderValue',
    'validFrom',
    'validUntil',
    'active',
  ])

  function onSubmitHandler(data: FormSchema) {
    const result: Omit<CreateDiscountInput | UpdateDiscountInput, 'shopId'> = {
      unit: data.unit as DiscountUnit,
      couponCode: data.couponCode,
      value: Number(data.value),
      validFrom: data.validFrom,
      validUntil: data.validUntil,
      active: data.active,
      minimumOrderValue: Number(data.minimumOrderValue),
    }

    onSave(result)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="pb-5 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
          {typeof discount === 'object' ? (
            <p className="mt-1 max-w-xl text-sm leading-5 text-gray-500">
              Mettez à jour votre produit pour augmenter vos ventes : affinez votre prix de
              vente, retravaillez la photo de votre produit ou de votre note de dégustation.
            </p>
          ) : (
            <p className="mt-1 max-w-xl text-sm leading-5 text-gray-500">
              Ajouter un code promotionnel en complétant le formulaire.
            </p>
          )}
          <div className="flex space-x-3">
            <span className="shadow-sm rounded-md">
              <button
                onClick={() => history.goBack()}
                className="inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
              >
                Annuler
              </button>
            </span>
            <span className="shadow-sm rounded-md">
              <Button type="submit">Enregistrer</Button>
            </span>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 grid grid-cols-3 gap-x-4">
        <div className="mt-6 sm:col-span-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
          {/* code */}
          <div className="sm:col-span-2">
            <StringField
              register={register}
              label="Code"
              required={true}
              name="couponCode"
              value={couponCode}
              error={errors.couponCode}
            />
          </div>
          {/* type */}
          <div className="sm:col-span-1">
            <SelectField
              register={register}
              label="Type"
              required={true}
              name="unit"
              options={discountUnits}
              value={unit}
              error={errors.unit}
            />
          </div>
          {/* Value */}
          <div className="sm:col-span-1">
            <NumberField
              register={register}
              name="value"
              label="Valeur"
              required={true}
              min={0.0}
              max={10000.0}
              step={0.01}
              unit={unit === DiscountUnit.EURO ? 'EUR (€)' : '%'}
              placeholder="0.0"
              error={errors.value}
            />
          </div>
          {/* Date debut*/}
          <div className="sm:col-span-1">
            <DatePickerField
              label="Date de début"
              required={true}
              name="validFrom"
              value={validFrom?.toString}
              error={errors.validFrom}
              control={control}
              minDate={new Date()}
              maxDate={computeBorderDate(validUntil ?? new Date(), -1)}
            />
          </div>
          {/* Date fin*/}
          <div className="sm:col-span-1">
            <DatePickerField
              label="Date de fin"
              required={true}
              name="validUntil"
              value={validUntil?.toString()}
              error={errors.validUntil}
              control={control}
              minDate={
                validFrom
                  ? computeBorderDate(validFrom, 1)
                  : computeBorderDate(new Date(), 1)
              }
            />
          </div>
          {/* minimumOrderValue */}
          <div className="sm:col-span-2">
            <NumberField
              register={register}
              name="minimumOrderValue"
              label="Montant panier minimum"
              required={true}
              min={0.0}
              max={10000.0}
              step={0.01}
              placeholder="0.0"
              unit="EUR (€)"
              error={errors.minimumOrderValue}
            />
          </div>
          {/* Statut */}
          <div className="sm:col-span-2">
            <SelectField
              register={register}
              label="Statut"
              required={true}
              name="active"
              value={active}
              options={activeStates}
              error={errors.active}
            />
          </div>
        </div>
      </div>
    </form>
  )
}

interface DiscountFormProps {
  discount?: GetDiscount_discount
  onSave: (data: Omit<CreateDiscountInput | UpdateDiscountInput, 'shopId'>) => void
}
