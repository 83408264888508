import { gql } from '@apollo/client'
import { SHOP_DETAILS } from './fragments'

export const GET_SHOP = gql`
  query GetShop($id: ID!) {
    shop(id: $id) {
      ...ShopDetails
    }
  }
  ${SHOP_DETAILS}
`
