import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  productDescription,
  productCategory,
  getPictoColorFromValue,
  getPictoLabel,
} from '../../../core/models/product/utils'
import { ProductDetails } from '../../../core/models/product/__generated__/ProductDetails'
import { ProductPicto } from '../../../core/models/__generated__/globalTypes'
import { reduceLongSenteces } from '../../../core/utils'

export default function ProductRow(props: ProductRowProps) {
  const history = useHistory()
  const { product } = props

  function renderProductPictos(pictos: ProductPicto[]) {
    return pictos.map((picto, index) => (
      <span
        key={index}
        className="m-1 ml-0 px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
        style={{
          color: '#FFFFFF',
          backgroundColor: getPictoColorFromValue(picto),
        }}
      >
        {getPictoLabel(picto)}
      </span>
    ))
  }

  return (
    <tr>
      <td className="px-6 py-4 whitespace-no-wrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <img className="h-10 w-10 rounded-md" src={product.imageUri} alt="produit" />
          </div>
          <div className="ml-4">
            <div className="text-sm leading-5 font-medium text-gray-900">
              {product.name} - {product.variantName}
            </div>
            <div className="text-sm leading-5 text-gray-500">
              {reduceLongSenteces(productDescription(product))}
            </div>
            {!!product.pictos && (
              <div className="text-sm leading-5 text-gray-500">
                {renderProductPictos(product.pictos)}
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="px-4 py-4 whitespace-no-wrap">
        <div className="text-sm leading-5 text-gray-500">{productCategory(product)}</div>
      </td>
      <td className="px-4 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        <span className="text-cool-gray-900 font-medium">{product.price}€ </span>
        EUR
        {product.crossedPrice && (
          <span className="text-cool-gray-900 font-medium line-through ml-1">
            {product.crossedPrice}€{' '}
          </span>
        )}
      </td>
      <td className="px-4 py-4 whitespace-no-wrap">
        {product.active ? (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
            Publié
          </span>
        ) : (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 text-gray-900">
            Non-publié
          </span>
        )}
      </td>
      <td className="px-4 py-4 whitespace-no-wrap">
        {product.stock > 3 && (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            En stock
          </span>
        )}
        {product.stock > 0 && product.stock <= 3 && (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
            Stock faible
          </span>
        )}
        {product.stock <= 0 && (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            Produit épuisé
          </span>
        )}
      </td>
      <td className="px-4 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
        <div
          className="text-purple-600 hover:text-purple-900 cursor-pointer"
          onClick={() => history.push(`/products/${product.id}`)}
        >
          → Éditer
        </div>
      </td>
    </tr>
  )
}

interface ProductRowProps {
  product: ProductDetails
}
