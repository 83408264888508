import { gql } from '@apollo/client'
import { DISCOUNT_DETAILS } from './fragments'

export const CREATE_DISCOUNT = gql`
  mutation CreateDiscount($input: CreateDiscountInput!) {
    createDiscount(input: $input) {
      ...DiscountDetails
    }
  }
  ${DISCOUNT_DETAILS}
`

export const UPDATE_DISCOUNT = gql`
  mutation UpdateDiscount($id: ID!, $input: UpdateDiscountInput!) {
    updateDiscount(id: $id, input: $input) {
      ...DiscountDetails
    }
  }
  ${DISCOUNT_DETAILS}
`
