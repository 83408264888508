import React, { useState, useCallback } from 'react'

import { useOrders } from '../../core/models/order/hooks'
import TableNavigation from '../../lib/components/tables/TableNavigation'
import Container from '../../lib/components/layout/Container'
import NoOrders from '../../lib/containers/orders/NoOrders'
import OrderRow from '../../lib/containers/orders/OrderRow'
import Loading from '../../lib/components/layout/Loading'
import { OrderStatus } from '../../core/models/__generated__/globalTypes'
import { OrderStatusTranslation, OrderStatusColor } from '../../core/models/order/utils'
import StringField from '../../lib/components/fields/StringField'
import SingleSelectMenu from '../../lib/components/menus/SingleSelectMenu'

const FETCHING_LIMIT = 10

export default function OrdersPage() {
  const [page, setPage] = useState(0)
  const [nameFilter, setNameFilter] = useState('')
  const [statusInFilter, setStatusInFilter] = useState<OrderStatus[]>([])
  const { orders, fetchMore } = useOrders({
    first: FETCHING_LIMIT,
    statusIn: statusInFilter,
    name: nameFilter,
  })

  const onNextHandler = useCallback(() => {
    if (orders) {
      fetchMore()
      if (page + 1 < Math.ceil(orders.totalCount / FETCHING_LIMIT)) {
        setPage(page + 1)
      }
    }
  }, [page, setPage, orders, fetchMore])

  const onPreviousHandler = useCallback(() => {
    if (orders && page > 0) {
      setPage(page - 1)
    }
  }, [orders, page, setPage])

  if (!orders) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  const { edges } = orders

  if (edges.length === 0 && !nameFilter && statusInFilter.length === 0) {
    return (
      <Container>
        <NoOrders />
      </Container>
    )
  }

  return (
    <Container>
      <div className="pt-2 pb-6 md:py-6">
        <h1 className="text-2xl font-semibold text-gray-900">Commandes</h1>
        <div className="pb-5 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
          <p className="mt-1 max-w-xl text-sm leading-5 text-gray-500">
            Suivez les nouvelles commandes reçues depuis votre boutique en ligne.
          </p>
        </div>
        <div className="py-4">
          <div className="grid grid-flow-col auto-cols-max mb-2 gap-5">
            <SingleSelectMenu
              initialSelectedItem={statusInFilter.length ? statusInFilter[0] : undefined}
              label="Filtrer par statut"
              items={[
                OrderStatus.PAYED,
                OrderStatus.VALIDATED,
                OrderStatus.PREPARED,
                OrderStatus.DELIVERED,
                OrderStatus.CANCELED,
                OrderStatus.REFUNDED,
              ]}
              onChange={(item) =>
                setStatusInFilter([item].filter((x) => !!x) as OrderStatus[])
              }
              renderItem={(item, key) => {
                if (item === OrderStatus.CANCELED) {
                  return (
                    <>
                      <span
                        key={key}
                        className="m-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                        style={{
                          backgroundColor: OrderStatusColor.CANCELED.background,
                          color: OrderStatusColor.CANCELED.text,
                        }}
                      >
                        {OrderStatusTranslation.CANCELED}
                      </span>
                      <span
                        key={key}
                        className="m-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                        style={{ backgroundColor: '#FFF2BA', color: '#907B1E' }}
                      >
                        Non remboursée
                      </span>
                    </>
                  )
                }

                if (item === OrderStatus.REFUNDED) {
                  return (
                    <>
                      <span
                        key={key}
                        className="m-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                        style={{
                          backgroundColor: OrderStatusColor.CANCELED.background,
                          color: OrderStatusColor.CANCELED.text,
                        }}
                      >
                        {OrderStatusTranslation.CANCELED}
                      </span>
                      <span
                        key={key}
                        className="m-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                        style={{
                          backgroundColor: OrderStatusColor.REFUNDED.background,
                          color: OrderStatusColor.REFUNDED.text,
                        }}
                      >
                        {OrderStatusTranslation.REFUNDED}
                      </span>
                    </>
                  )
                }

                const text =
                  item === OrderStatus.PAYED ? 'À confirmer' : OrderStatusTranslation[item]
                const backgroundColor =
                  item === OrderStatus.PAYED ? '#FFF2BA' : OrderStatusColor[item].background
                const color =
                  item === OrderStatus.PAYED ? '#907B1E' : OrderStatusColor[item].text

                return (
                  <>
                    <span
                      key={key}
                      className="m-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                      style={{ backgroundColor, color }}
                    >
                      {text}
                    </span>
                  </>
                )
              }}
              placeholder="Pas de filtre"
            />
            <div>
              <StringField
                value={nameFilter}
                name="name"
                label="Rechercher par numéro"
                placeholder="C-2020-000000005"
                onSubmit={(value) => setNameFilter(value)}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Numéro
                        </th>
                        <th className="px-2 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Client
                        </th>
                        <th className="px-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Produits
                        </th>
                        <th className="px-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Montant
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Suivi
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Paiement
                        </th>
                        <th className="px-6 py-3 bg-gray-50" />
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {edges
                        .slice(
                          page * FETCHING_LIMIT,
                          page * FETCHING_LIMIT + FETCHING_LIMIT,
                        )
                        .map(({ node }) => (
                          <OrderRow key={node.id} order={node} />
                        ))}
                    </tbody>
                  </table>
                  {edges.length === 0 && (
                    <div className="w-full p-5 bg-white text-center text-sm leading-5 font-medium text-gray-900">
                      Pas de commandes avec les filtres choisis
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <TableNavigation
            hasNextPage={page + 1 < Math.ceil(orders.totalCount / FETCHING_LIMIT)}
            hasPreviousPage={page > 0}
            onNext={onNextHandler}
            onPrevious={onPreviousHandler}
          />
        </div>
      </div>
    </Container>
  )
}
