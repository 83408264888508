import { gql } from '@apollo/client'
import { SHOP_DETAILS } from './fragments'

export const UPDATE_SHOP = gql`
  mutation UpdateShop($id: ID!, $input: UpdateShopInput!) {
    updateShop(id: $id, input: $input) {
      ...ShopDetails
    }
  }
  ${SHOP_DETAILS}
`

export const ACTIVATE_SHOP = gql`
  mutation ActivateShop($id: ID!) {
    activateShop(id: $id) {
      ...ShopDetails
    }
  }
  ${SHOP_DETAILS}
`

export const DEACTIVATE_SHOP = gql`
  mutation DeactivateShop($id: ID!) {
    deactivateShop(id: $id) {
      ...ShopDetails
    }
  }
  ${SHOP_DETAILS}
`

export const UPDATE_SHOP_IMAGES = gql`
  mutation UpdateShopImages($id: ID!, $input: [UpdateShopImagesInput!]!) {
    updateShopImages(id: $id, input: $input) {
      ...ShopDetails
    }
  }
  ${SHOP_DETAILS}
`

export const UPDATE_SHOP_HOME_DELIVERY = gql`
  mutation UpdateShopHomeDelivery($id: ID!, $input: UpdateShopHomeDeliveryInput!) {
    updateShopHomeDelivery(id: $id, input: $input) {
      ...ShopDetails
    }
  }
  ${SHOP_DETAILS}
`

export const UPDATE_SHOP_ORDER_SETTINGS = gql`
  mutation UpdateShopOrderSettings($id: ID!, $input: OrderSettingsInput!) {
    updateShopOrderSettings(id: $id, input: $input) {
      ...ShopDetails
    }
  }
  ${SHOP_DETAILS}
`
