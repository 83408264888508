import React from 'react'
import { useAlerts } from '../../../core/contexts/alerts'
import { useDiscount, useUpdateDiscount } from '../../../core/models/discount/hooks'
import { UpdateDiscountInput } from '../../../core/models/__generated__/globalTypes'
import Loading from '../../components/layout/Loading'
import DiscountForm from './DiscountForm'

export default function UpdateDiscount(props: UpdateDiscountsProps) {
  const { id } = props
  const { pushSuccessAlert, pushErrorAlert } = useAlerts()
  const { discount } = useDiscount({ id })
  const { updateDiscount } = useUpdateDiscount({
    onCompleted: () => pushSuccessAlert('Vos modifications sont enregistrées'),
    onError: (error) => {
      if (error.message === 'code promo already exist') {
        pushErrorAlert('Un autre code existe avec le même identifiant')
      } else {
        pushErrorAlert("Une erreur s'est produite, essayez d'enregistrer à nouveau")
      }
    },
  })

  function onSaveHandler(data: Omit<UpdateDiscountInput, 'shopId'>) {
    updateDiscount({ id, input: data as UpdateDiscountInput })
  }

  if (typeof discount === 'undefined') {
    return <Loading />
  }

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">{discount.couponCode}</h1>
      </div>
      <DiscountForm discount={discount} onSave={onSaveHandler} />
    </>
  )
}

interface UpdateDiscountsProps {
  id: string
}
