import React from 'react'
import { useHistory } from 'react-router-dom'
import useIntercom from '../../../core/hooks/useIntercom'
import { GetShop_shop } from '../../../core/models/shop/__generated__/GetShop'

export default function NoProducts(props: NoProductsProps) {
  const { shop } = props
  const history = useHistory()
  const intercom = useIntercom()

  return (
    <div>
      <div className="mt-5 bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Vous n'avez pas encore de produit
          </h3>
          {shop.franchise === 'Cavavin' ? (
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
              <div className="max-w-xl text-sm leading-5 text-gray-500">
                <p>
                  Veuillez patienter, vos produits seront bientôt disponibles sur Potico.
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm leading-5 text-gray-500">
                  <p>
                    Lier un maximum de produits de votre choix de votre catalogue{' '}
                    <i>Wino la Caisse connectée</i> sur votre plateforme de{' '}
                    <i>click-and-collect</i>.
                  </p>
                </div>
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button
                      onClick={() => history.push('/products/create')}
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700 transition ease-in-out duration-150"
                    >
                      Ajouter mon premier produit
                    </button>
                  </span>
                </div>
              </div>
              <div className="mt-4 text-sm leading-5">
                <span
                  className="font-medium text-purple-600 hover:text-purple-500 transition ease-in-out duration-150 cursor-pointer"
                  onClick={intercom.show}
                >
                  Prendre contact avec l'équipe Wino pour demander conseil →
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

interface NoProductsProps {
  shop: GetShop_shop
}
