import { gql } from '@apollo/client'

export const PRODUCT_DETAILS = gql`
  fragment ProductDetails on Product {
    id
    createdAt
    updatedAt
    name
    variantName
    stock
    imageUri
    price
    tax
    kind
    color
    pictos
    producer
    country
    region
    family
    wineType
    whiteWineType
    beerType
    designation
    description
    tastingNote
    active
    variantId
    editable
    crossedPrice
  }
`
