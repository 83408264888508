import { gql } from '@apollo/client'
import { DISCOUNT_DETAILS } from './fragments'

export const GET_DISCOUNTS = gql`
  query GetDiscounts(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $shopId: String!
  ) {
    discounts(
      first: $first
      last: $last
      before: $before
      after: $after
      where: { shopId: { _in: [$shopId] } }
    ) {
      edges {
        cursor
        node {
          ...DiscountDetails
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
  ${DISCOUNT_DETAILS}
`

export const GET_DISCOUNT = gql`
  query GetDiscount($id: ID!) {
    discount(id: $id) {
      ...DiscountDetails
    }
  }
  ${DISCOUNT_DETAILS}
`
