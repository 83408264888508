import React, { useCallback } from 'react'
import { useSnackbar, SnackbarProvider } from 'notistack'
import Alert, { AlertVariant } from '../../lib/components/alerts/Alert'

export function useAlerts() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const pushAlert = useCallback(
    (text: React.ReactNode, variant: AlertVariant) => {
      enqueueSnackbar({ text, variant })
    },
    [enqueueSnackbar],
  )

  const pushSuccessAlert = useCallback(
    (text: React.ReactNode) => {
      pushAlert(text, AlertVariant.Success)
    },
    [pushAlert],
  )

  const pushErrorAlert = useCallback(
    (text: React.ReactNode) => {
      pushAlert(text, AlertVariant.Error)
    },
    [pushAlert],
  )

  const pushWarningAlert = useCallback(
    (text: React.ReactNode) => {
      pushAlert(text, AlertVariant.Warning)
    },
    [pushAlert],
  )

  const pushInfoAlert = useCallback(
    (text: React.ReactNode) => {
      pushAlert(text, AlertVariant.Info)
    },
    [pushAlert],
  )

  const closeAlert = useCallback((key: React.ReactText) => closeSnackbar(key), [
    closeSnackbar,
  ])

  return {
    pushSuccessAlert,
    pushErrorAlert,
    pushWarningAlert,
    pushInfoAlert,
    closeAlert,
  }
}

export function AlertsProvider(props: AlertsProviderProps) {
  const { children } = props
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      content={(key, message: { text: string; variant: AlertVariant }) => (
        <Alert id={key} message={message} />
      )}
    >
      {children}
    </SnackbarProvider>
  )
}

interface AlertsProviderProps {
  children: React.ReactNode
}
