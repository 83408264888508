import React from 'react'

export default function TableNavigation(props: TableNavigationProps) {
  const { hasPreviousPage, hasNextPage, onNext, onPrevious } = props

  if (!hasNextPage && !hasPreviousPage) {
    return null
  }

  return (
    <nav className="border-t border-gray-200 py-4 px-4 flex items-center justify-between sm:px-0">
      <div className="w-0 flex-1 flex">
        {hasPreviousPage && (
          <div
            className="-mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150 cursor-pointer"
            onClick={onPrevious}
          >
            <svg
              className="mr-3 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            Précédent
          </div>
        )}
      </div>
      <div className="w-0 flex-1 flex justify-end">
        {hasNextPage && (
          <div
            className="-mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150 cursor-pointer"
            onClick={onNext}
          >
            Suivant
            <svg
              className="ml-3 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </div>
    </nav>
  )
}

interface TableNavigationProps {
  hasPreviousPage: boolean | null
  hasNextPage: boolean | null
  onNext: () => void
  onPrevious: () => void
}
