import { gql } from '@apollo/client'
import { ORDER_DETAILS } from './fragments'

export const CHANGE_ORDER_STATUS = gql`
  mutation ChangeOrderStatus($id: ID!, $status: PermitedOrderStatus!) {
    changeOrderStatus(id: $id, status: $status) {
      ...OrderDetails
    }
  }
  ${ORDER_DETAILS}
`
