import React from 'react'
import { OrderDetails } from '../../../core/models/order/__generated__/OrderDetails'
import { OrderStatus } from '../../../core/models/__generated__/globalTypes'
import { format } from 'date-fns'
import frLocale from 'date-fns/locale/fr'

type Event = {
  eventName: string
  eventBy?: string
  eventIcon: React.ReactNode
  eventTime: string
}

export default function OrderTimelineDetails(props: OrderTimelineDetailsProps) {
  const { order } = props

  const timeline: Event[] = [
    OrderStatus.PAYED,
    OrderStatus.VALIDATED,
    OrderStatus.PREPARED,
    OrderStatus.DELIVERED,
    OrderStatus.CANCELED,
    OrderStatus.REFUNDED,
  ]
    .map((status) => {
      switch (status) {
        case OrderStatus.PAYED:
          if (order.createdAt && order.payedAt) {
            return {
              eventName: `Commande crée`,
              eventBy: 'par le client',
              eventIcon: (
                <span className="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </span>
              ),
              eventTime: order.payedAt,
            }
          }
          break
        case OrderStatus.VALIDATED:
          if (order.validatedAt) {
            return {
              eventName: 'Commande confirmée',
              eventIcon: (
                <span className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                  </svg>
                </span>
              ),
              eventTime: order.validatedAt,
            }
          }
          break
        case OrderStatus.PREPARED:
          if (order.preparedAt) {
            return {
              eventName: 'Commande prête',
              eventIcon: (
                <span className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </span>
              ),
              eventTime: order.preparedAt,
            }
          }
          break
        case OrderStatus.DELIVERED:
          if (order.deliveredAt) {
            return {
              eventName: `Commande livrée`,
              eventIcon: (
                <span className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                    />
                  </svg>
                </span>
              ),
              eventTime: order.deliveredAt,
            }
          }
          break
        case OrderStatus.CANCELED:
          if (order.canceledAt) {
            return {
              eventName: 'Commande annulée',
              eventIcon: (
                <span className="h-8 w-8 rounded-full bg-red-500 flex items-center justify-center ring-8 ring-white">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              ),
              eventTime: order.canceledAt,
            }
          }
          break
        case OrderStatus.REFUNDED:
          if (order.refundedAt) {
            return {
              eventName: 'Commande remboursée',
              eventIcon: (
                <span className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </span>
              ),
              eventTime: order.refundedAt,
            }
          }
          break
      }
      return undefined
    })
    .filter((x) => !!x) as Event[]

  function renderTimeline(timeline: Event[]) {
    return timeline.map((event, index) => (
      <li key={index}>
        <div className="relative pb-8">
          {index < timeline.length - 1 && (
            <span
              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
          )}
          <div className="relative flex space-x-3 items-center">
            <div>{event.eventIcon}</div>
            <div className="min-w-0 flex-1 flex justify-between space-x-4">
              <div className="grid items-center">
                <p className="text-sm text-gray-500 items-center">{event.eventName}</p>
                {event.eventBy && (
                  <p className="text-sm text-gray-500 items-center">{event.eventBy}</p>
                )}
              </div>
              <div className="text-right text-sm whitespace-nowrap text-gray-500">
                <p>
                  {format(new Date(event.eventTime), 'dd/MM/yyyy', {
                    locale: frLocale,
                  })}
                </p>
                <p>
                  {format(new Date(event.eventTime), 'à HH:mm', {
                    locale: frLocale,
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
    ))
  }

  return (
    <section className="lg:col-start-3 lg:col-span-1">
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
          Historique
        </h2>
        <div className="mt-6 flow-root">
          <ul className="-mb-8">{renderTimeline(timeline)}</ul>
        </div>
      </div>
    </section>
  )
}

interface OrderTimelineDetailsProps {
  order: OrderDetails
}
