import React, { useState } from 'react'
import MobileSideBar from '../side-bar/MobileSideBar'
import DesktopSideBar from '../side-bar/DesktopSideBar'

export default function Dashboard(props: DashboardProps) {
  const [open, setOpen] = useState(false)

  const menu = [
    {
      to: '/orders',
      icon: (
        <svg
          className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-600 group-focus:text-gray-500 transition ease-in-out duration-150"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
          />
        </svg>
      ),
      title: 'Commandes',
    },
    {
      to: '/products',
      icon: (
        <svg
          className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-600 group-focus:text-gray-500 transition ease-in-out duration-150"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
          />
        </svg>
      ),
      title: 'Produits',
    },
    {
      to: '/discounts',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-600 group-focus:text-gray-500 transition ease-in-out duration-150"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
          />
        </svg>
      ),
      title: 'Codes promotionnels',
    },
  ]

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <MobileSideBar open={open} onClose={() => setOpen(false)} menu={menu} />
      <DesktopSideBar menu={menu} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
            aria-label="Open sidebar"
            onClick={() => setOpen(true)}
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <main
          className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
          tabIndex={0}
        >
          {props.children}
        </main>
      </div>
    </div>
  )
}

interface DashboardProps {
  children: React.ReactChildren
}
