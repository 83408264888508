import React from 'react'
import { useAlerts } from '../../../core/contexts/alerts'
import { useProduct, useUpdateProduct } from '../../../core/models/product/hooks'
import { UpdateProductInput } from '../../../core/models/__generated__/globalTypes'
import Loading from '../../components/layout/Loading'
import ProductForm from './ProductForm'

export default function UpdateProduct(props: UpdateProductProps) {
  const { id } = props
  const { pushSuccessAlert, pushErrorAlert } = useAlerts()
  const { product } = useProduct({ id })
  const { updateProduct } = useUpdateProduct({
    onCompleted: () => pushSuccessAlert('Vos modifications sont enregistrées'),
    onError: (error) => {
      if (error.message === 'Product already exist') {
        pushErrorAlert('Un autre produit existe avec le même identifiant')
      } else {
        pushErrorAlert("Une erreur s'est produite, essayez d'enregistrer à nouveau")
      }
    },
  })

  function onSaveHandler(data: Omit<UpdateProductInput, 'shopId'>) {
    updateProduct({ id, input: data })
  }

  if (typeof product === 'undefined') {
    return <Loading />
  }

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          {product.name} - {product.variantName}
        </h1>
      </div>
      <ProductForm product={product} onSave={onSaveHandler} />
    </>
  )
}

interface UpdateProductProps {
  id: string
}
