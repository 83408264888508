import React, { useRef, useState } from 'react'
import classNames from 'classnames'

import Button from '../../components/buttons/Button'
import DropDown from '../../components/menus/DropDown'
import {
  OrderStatus,
  PermitedOrderStatus,
} from '../../../core/models/__generated__/globalTypes'
import { generateAllowedStates } from '../../../core/models/order/utils'
import { useClickOutside } from '../../../core/hooks/useClickOutside'
import ChangeStatusModal from '../../../lib/containers/orders/ChangeStatusModal'

export default function ChangeStatusDropDown(props: ChangeStatusDropDownProps) {
  const { onChangeStatus, status } = props
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<PermitedOrderStatus | undefined>(
    undefined,
  )
  useClickOutside(ref, () => setOpen(false), [])

  const allowedStates = generateAllowedStates(status)

  const actions = [
    {
      status: PermitedOrderStatus.VALIDATED,
      text: 'Commande confirmée',
      safe: false,
    },
    {
      status: PermitedOrderStatus.PREPARED,
      text: 'Commande prête',
      safe: false,
    },
    {
      status: PermitedOrderStatus.DELIVERED,
      text: 'Commande livrée',
      safe: false,
    },
    {
      status: PermitedOrderStatus.CANCELED,
      text: 'Commande annulée',
      safe: false,
    },
    {
      status: PermitedOrderStatus.REFUNDED,
      text: 'Commande remboursée',
      safe: false,
    },
  ]

  function renderAction(action: {
    status: PermitedOrderStatus
    text: string
    safe: boolean
  }) {
    const allowed = allowedStates.includes(action.status)
    let color = allowed ? 'text-gray-700' : 'text-gray-300'

    if (
      [PermitedOrderStatus.CANCELED, PermitedOrderStatus.REFUNDED].includes(action.status)
    ) {
      color = allowed ? 'text-red-500' : 'text-red-300'
    }

    return (
      <div
        key={action.status}
        onClick={() => {
          if (allowed) {
            if (action.safe) {
              onChangeStatus(action.status)
              setSelectedStatus(undefined)
            } else {
              setSelectedStatus(action.status)
            }
            setOpen(false)
          }
        }}
        className={classNames(
          'block px-4 py-2 text-sm',
          color,
          allowed && 'hover:bg-gray-100 hover:text-gray-900 cursor-pointer',
        )}
        role="menuitem"
      >
        {action.text}
      </div>
    )
  }

  return (
    <>
      <div ref={ref}>
        <span className="shadow-sm rounded-md">
          {![OrderStatus.DELIVERED, OrderStatus.REFUNDED].includes(status) && (
            <Button onClick={() => setOpen(!open)}>Changer le statut</Button>
          )}
        </span>
        <div className="relative z-10">
          <div className="mt-1 absolute top-0 right-0">
            <DropDown open={open}>
              <div className="w-80 rounded-mdbg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200">
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {actions.map((action) => renderAction(action))}
                </div>
              </div>
            </DropDown>
          </div>
        </div>
      </div>
      <div className="absolute">
        {!!selectedStatus && (
          <ChangeStatusModal
            open={!!selectedStatus}
            status={selectedStatus}
            onCancel={() => setSelectedStatus(undefined)}
            onValidate={() => {
              onChangeStatus(selectedStatus as PermitedOrderStatus)
              setSelectedStatus(undefined)
            }}
          />
        )}
      </div>
    </>
  )
}

interface ChangeStatusDropDownProps {
  status: OrderStatus
  onChangeStatus: (status: PermitedOrderStatus) => void
}
