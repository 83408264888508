import React from 'react'
import classNames from 'classnames'
import TextInput from '../inputs/TextInput'

export default function TextField(props: TextFieldProps) {
  const {
    label,
    required,
    error,
    register,
    name,
    placeholder,
    information,
    rows,
    disabled = false,
    readOnly,
  } = props

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">
        {label} {required && '*'}
      </label>
      <div className="mt-1 rounded-md shadow-sm">
        <TextInput
          register={register}
          name={name}
          placeholder={placeholder}
          rows={rows}
          className={classNames(
            'transition duration-150 ease-in-out',
            !!error && 'border-red-600 focus:border-red-300',
            (readOnly || disabled) && 'opacity-50 cursor-not-allowed',
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
      </div>
      <p className="mt-1 text-sm text-gray-500">{information}</p>
      {!!error && (
        <p className="mt-1 text-sm text-red-600" id={`${name}-error`}>
          {error.message}
        </p>
      )}
    </>
  )
}

interface TextFieldProps {
  register: React.Ref<HTMLTextAreaElement>
  label: string
  required?: boolean
  name: string
  placeholder?: string
  rows: number
  information?: string
  error?: { message?: string }
  disabled?: boolean
  readOnly?: boolean
}
