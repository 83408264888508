import React, { createContext, useState, useContext } from 'react'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { APP_AUTH_TOKEN, API_BASE_URI, CURRENT_SHOP_ID } from '../constants'
import useIntercom from '../hooks/useIntercom'

function initIsAthenticated() {
  return ![null, undefined, ''].includes(localStorage.getItem(APP_AUTH_TOKEN))
}

export type AuthenticationContext = {
  isAuthenticated: boolean
  signIn: (
    email: string,
    password: string,
  ) => Promise<[boolean, { error?: string; status?: number }]>
  signOut: () => void
  signUp: (
    shopName: string, 
    xlsoftCode: string, 
    email: string, 
    inviteCode: string, 
    password: string
  ) => Promise<[boolean, { error?: { message: string }; status?: number }]>
}

const AuthenticationStore = createContext<AuthenticationContext>({
  isAuthenticated: initIsAthenticated(),
  signIn: async (_email: string, _password: string) => [false, {}],
  signOut: () => {},
  signUp: async (_shopName: string, _xlsoftCode: string, _email: string, _inviteCode: string, _password: string) => [false, {}]
})

const { Provider } = AuthenticationStore

export function AuthenticationProvider(props: { children: React.ReactNode }) {
  const { children } = props
  const auth = useProvideAuthentication()
  return <Provider value={auth}>{children}</Provider>
}

export function useSession() {
  return useContext(AuthenticationStore)
}

function useProvideAuthentication() {
  const intercom = useIntercom()
  const [isAuthenticated, setAuthenticated] = useState(initIsAthenticated())

  const signIn = async (
    email: string,
    password: string,
  ): Promise<[boolean, { error?: string; status?: number }]> => {
    try {
      const { data } = await axios.post(`${API_BASE_URI}/auth/sign-in`, {
        email,
        password,
      })
      localStorage.setItem(APP_AUTH_TOKEN, data.access_token)
      setAuthenticated(true)
      intercom.boot(email)
      Sentry.setUser({ email: email })
      return [true, {}]
    } catch (error) {
      localStorage.removeItem(APP_AUTH_TOKEN)
      setAuthenticated(false)
      return [false, { error: error?.response?.data, status: error?.response?.status }]
    }
  }

  const signUp = async (
    shopName: string, 
    xlsoftCode: string, 
    email: string, 
    inviteCode: string, 
    password: string
  ): Promise<[boolean, { error?: { message: string }; status?: number }]> => {
    try {
      const { data } = await axios.post(`${API_BASE_URI}/auth/sign-up`, {
        shopName, 
        xlsoftCode, 
        email, 
        inviteCode, 
        password
      })
      localStorage.setItem(APP_AUTH_TOKEN, data.access_token)
      setAuthenticated(true)
      Sentry.setUser({ email: email })
      return [true, {}]
    } catch (error) {
      localStorage.removeItem(APP_AUTH_TOKEN)
      setAuthenticated(false)
      return [false, { error: error?.response?.data, status: error?.response?.status }]
    }
  }

  const signOut = () => {
    localStorage.removeItem(APP_AUTH_TOKEN)
    localStorage.removeItem(CURRENT_SHOP_ID)
    setAuthenticated(false)
    // @ts-expect-error since it is not typed
    window._history.push('/sign-in')
  }

  return {
    isAuthenticated,
    signIn,
    signUp,
    signOut,
  }
}
