import React from 'react'
import { Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import { history } from '../../../core/history'
import { client } from '../../../core/api/apollo'
import MainRouter from '../router/MainRouter'
import '../../../tailwind.generated.css'
import { AuthenticationProvider } from '../../../core/contexts/session'
import { AlertsProvider } from '../../../core/contexts/alerts'

registerLocale('fr', fr)

if (typeof window !== 'undefined') {
  // @ts-expect-error since it is an extra property
  window._history = history
}

function App() {
  return (
    <AlertsProvider>
      <ApolloProvider client={client}>
        <AuthenticationProvider>
          <Router history={history}>
            <MainRouter />
          </Router>
        </AuthenticationProvider>
      </ApolloProvider>
    </AlertsProvider>
  )
}

export default App
