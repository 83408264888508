import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  OrderDetails,
  OrderDetails_products,
} from '../../../core/models/order/__generated__/OrderDetails'
import {
  DiscountUnit,
  OrderDeliveryOption,
} from '../../../core/models/__generated__/globalTypes'
import { productDescription } from '../../../core/models/product/utils'
import { reduceLongSenteces } from '../../../core/utils'

export default function OrderProductsDetails(props: OrderProductsDetailsProps) {
  const { order } = props
  const history = useHistory()

  function renderRow(product: OrderDetails_products) {
    return (
      <tr key={product.reference}>
        <td className="px-6 py-3 whitespace-nowrap">
          <div className="flex items-center">
            <div
              className="flex-shrink-0 h-10 w-10 cursor-pointer"
              onClick={() => history.push(`/products/${product.reference}`)}
            >
              <img className="h-10 w-10 rounded-full" src={product.imageUri} alt="" />
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                {reduceLongSenteces(product.name, 35)}
              </div>
              <p className="inline-block text-sm text-gray-500">
                {reduceLongSenteces(productDescription(product), 35)}
              </p>
            </div>
          </div>
        </td>
        <td className="px-6 py-3 whitespace-nowrap">
          <div className="text-sm text-gray-900">
            {product.crossedPrice && (
              <span className="line-through">{product.crossedPrice.toFixed(2)}€ </span>
            )}
            <span>{product.price.toFixed(2)}€ </span>
          </div>
          <div className="text-xs text-gray-500">{product.tax}% TVA</div>
        </td>
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900">
          {product.quantity}
        </td>
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900">
          {product.totalAmountIncludingTax.toFixed(2)}€
        </td>
      </tr>
    )
  }

  function renderOrderTotals() {
    return (
      <tr key="order-delivery-fees">
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900">
          {order.discount?.value ? 'Sous total' : 'Total'}
        </td>
        <td className="px-6 py-3 whitespace-nowrap text-sm" />
        {order.discount?.value ? (
          <td className="px-6 py-3 whitespace-nowrap text-sm" />
        ) : (
          <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900 font-bold">
            {order.totalAmountExcludingTaxes.toFixed(2)}€ HT
          </td>
        )}
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900 font-bold">
          {order.totalAmountIncludingTaxes.toFixed(2)}€ TTC
        </td>
      </tr>
    )
  }

  function renderDiscountValue() {
    const value = order.discount?.value
    const unit = order.discount?.unit

    if (value && unit === DiscountUnit.PERCENTAGE) {
      return `-${value}%`
    }
    if (value && unit === DiscountUnit.EURO) {
      return `-${value.toFixed(2)}€`
    }
  }

  function renderOrderDiscounts() {
    return (
      <tr key="order-delivery-fees">
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900 flex flex-row items-center">
          <div>Réduction</div>
          <span className="ml-5 border-dashed border-2 text-gray-500 p-1">{`${order.discount?.couponCode}`}</span>
        </td>

        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900" />
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900" />
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900">
          {renderDiscountValue()}
        </td>
      </tr>
    )
  }
  function renderOrderTotalsWithDiscount() {
    return (
      <tr key="order-delivery-fees">
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900">Total</td>
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900" />
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900 font-bold">
          {order.totalAmountExcludingTaxes.toFixed(2)}€ HT
        </td>
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900 font-bold">
          {order.totalAmountIncludingDiscount?.toFixed(2)}€ TTC
        </td>
      </tr>
    )
  }

  function renderOrderDeliveryDetails() {
    let text = ''
    let color = ''
    let backgroundColor = ''

    switch (order.delivery.option) {
      case OrderDeliveryOption.HOME_DELIVERY:
        text = 'À domicile'
        color = 'text-blue-800'
        backgroundColor = 'bg-blue-100'
        break
      case OrderDeliveryOption.SHOP_PICKUP:
        text = 'En magasin'
        color = 'text-yellow-800'
        backgroundColor = 'bg-yellow-100'
        break
    }

    return (
      <tr key="order-totals">
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900">Livraison</td>
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900" />
        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900">
          <span
            className={`m-1 px-2 py-0.5 inline-flex items-center rounded-md text-sm font-medium ${color} ${backgroundColor}`}
          >
            {text}
          </span>
        </td>
        <td className="px-6 py-3 whitespace-nowrap text-sm">
          {order.delivery.option === OrderDeliveryOption.HOME_DELIVERY && (
            <>
              <div className="text-sm text-gray-900 font-bold">
                {order.delivery.fees.toFixed(2)}€ TTC
              </div>
              <div className="text-xs text-gray-500">20% TVA</div>
            </>
          )}
        </td>
      </tr>
    )
  }

  return (
    <section>
      <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
        <div className="divide-y divide-gray-200">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="notes-title" className="text-lg font-medium text-gray-900">
              Détails du panier
            </h2>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden border-b border-gray-200">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Produits
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        P.U TTC
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Quantité
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {order.products.map(renderRow)}
                    {renderOrderDeliveryDetails()}
                    {renderOrderTotals()}
                    {order.discount?.value && renderOrderDiscounts()}
                    {order.discount?.value && renderOrderTotalsWithDiscount()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

interface OrderProductsDetailsProps {
  order: OrderDetails
}
