import { useQuery, useLazyQuery } from '@apollo/client'
import { GET_ACCOUNT } from './queries'
import { GetAccount, GetAccountVariables } from './__generated__/GetAccount'

export function useLazyGetAccount() {
  return useLazyQuery<GetAccount, GetAccountVariables>(GET_ACCOUNT)
}

export function useAccount(variables: GetAccountVariables) {
  const { data, error, loading } = useQuery<GetAccount>(GET_ACCOUNT, { variables })

  return { account: data?.account, error, loading }
}
