import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './index.css'
import App from './lib/containers/app/App'
import reportWebVitals from './reportWebVitals'
import { PRODUCTION, SENTRY_DNS_URI } from './core/constants'

if (PRODUCTION && typeof SENTRY_DNS_URI === 'string') {
  Sentry.init({
    dsn: SENTRY_DNS_URI,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
