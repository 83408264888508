import React from 'react'
import { OrderDetails } from '../../../core/models/order/__generated__/OrderDetails'
import { OrderDeliveryOption } from '../../../core/models/__generated__/globalTypes'

export default function OrderCustomerDetails(props: OrderCustomerDetailsProps) {
  const { order } = props

  return (
    <section>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="applicant-information-title"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Informations client
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Ce sont les informations saisies par le client au moment du paiement.
          </p>
        </div>
        <div className="border-t border-gray-200 sm:px-6">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Nom complet de l'acheteur
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{order.customer.name}</span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Mail</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{order.customer.email}</span>
              </dd>
            </div>
            {/* In case one day we add phone number */}
            {/* <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Téléphone</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow"></span>
              </dd>
            </div> */}
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Adresse de facturation</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid">
                  <p className="flex-grow">{order.billing.name}</p>
                  <p>
                    {[
                      order.billing.address.line1,
                      order.billing.address.line2,
                      order.billing.address.postalCode,
                      order.billing.address.city,
                    ]
                      .filter((x) => !!x)
                      .join(', ')}
                  </p>
                </div>
              </dd>
            </div>
            {order.delivery.option === OrderDeliveryOption.HOME_DELIVERY && (
              <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Adresse de livraison</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div className="grid">
                    <p>{order.shipping?.name}</p>
                    <p>
                      {[
                        order.shipping?.address?.line1,
                        order.shipping?.address?.line2,
                        order.shipping?.address?.postalCode,
                        order.shipping?.address?.city,
                      ]
                        .filter((x) => !!x)
                        .join(', ')}
                    </p>
                  </div>
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  )
}

interface OrderCustomerDetailsProps {
  order: OrderDetails
}
