import { useCallback } from 'react'
import { useQuery, useMutation, MutationHookOptions } from '@apollo/client'
import { GET_PRODUCTS, GET_PRODUCT } from './queries'
import { CREATE_PRODUCT, UPDATE_PRODUCT } from './mutations'
import { GetProducts, GetProductsVariables } from './__generated__/GetProducts'
import { GetProduct, GetProductVariables } from './__generated__/GetProduct'
import { useShopsContext } from '../../contexts/shops'
import usePagination from '../../hooks/usePagination'
import { CreateProductVariables, CreateProduct } from './__generated__/CreateProduct'
import { UpdateProductVariables, UpdateProduct } from './__generated__/UpdateProduct'
import { CreateProductInput } from '../__generated__/globalTypes'

export function useProducts(variables: Omit<GetProductsVariables, 'shopId'>) {
  const { currentShop } = useShopsContext()
  const { loading, data, error, fetchMore } = usePagination<
    GetProducts,
    GetProductsVariables
  >(GET_PRODUCTS, 'products', {
    variables: { ...variables, shopId: currentShop },
    fetchPolicy: 'cache-and-network',
  })

  return { loading, error, products: data?.products, fetchMore }
}

export function useProduct(variables: GetProductVariables) {
  const { loading, data, error } = useQuery<GetProduct, GetProductVariables>(GET_PRODUCT, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

  return { loading, error, product: data?.product }
}

export function useCreateProduct(
  options?: MutationHookOptions<CreateProduct, CreateProductVariables>,
) {
  const { currentShop } = useShopsContext()
  const [mutation, { data, loading }] = useMutation<CreateProduct, CreateProductVariables>(
    CREATE_PRODUCT,
    options,
  )

  const createProduct = useCallback(
    async (variables: Omit<CreateProductInput, 'shopId'>) => {
      await mutation({
        variables: { input: { ...variables, shopId: currentShop } },
      })
    },
    [mutation, currentShop],
  )

  return { createProduct, product: data?.createProduct, loading }
}

export function useUpdateProduct(
  options?: MutationHookOptions<UpdateProduct, UpdateProductVariables>,
) {
  const [mutation, { data, loading }] = useMutation<UpdateProduct, UpdateProductVariables>(
    UPDATE_PRODUCT,
    options,
  )

  const updateProduct = useCallback(
    async (variables: UpdateProductVariables) => {
      await mutation({ variables })
    },
    [mutation],
  )

  return { updateProduct, product: data?.updateProduct, loading }
}
