import React, { useState } from 'react'

import { SelectMenu } from './SelectMenu'

export default function SingleSelectMenu<T extends string>(
  props: SingleSelectMenuProps<T>,
) {
  const {
    label,
    initialSelectedItem,
    items,
    onChange = () => {},
    renderItem,
    placeholder,
  } = props
  const [selectedItem, setSelectedItem] = useState<T | undefined>(initialSelectedItem)

  return (
    <SelectMenu
      label={label}
      items={items}
      selectedItems={[selectedItem].filter((x) => !!x) as T[]}
      onClose={() => onChange(selectedItem)}
      onSelect={(item) => {
        if (selectedItem === item) {
          setSelectedItem(undefined)
        } else {
          setSelectedItem(item)
        }
      }}
      renderItem={renderItem}
      placeholder={placeholder}
    />
  )
}

interface SingleSelectMenuProps<T extends string> {
  label?: string
  items: T[]
  initialSelectedItem?: T
  onChange?: (items: T | undefined) => void
  renderItem: (item: T, key: string | number) => React.ReactNode
  placeholder: string
}
