import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import AuthenticatedRouter from './AuthenticatedRouter'
import SignInPage from '../../../pages/public/SignInPage'
import SignUpPage from '../../../pages/public/SignUpPage'

export function MainRouter() {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/orders" />} />
      <Route exact path="/sign-in" component={SignInPage} />
      <Route exact path="/sign-up" component={SignUpPage} />
      <AuthenticatedRouter />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default MainRouter
