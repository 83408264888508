/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DiscountUnit {
  EURO = "EURO",
  PERCENTAGE = "PERCENTAGE",
}

export enum OrderDeliveryOption {
  HOME_DELIVERY = "HOME_DELIVERY",
  SHOP_PICKUP = "SHOP_PICKUP",
}

export enum OrderStatus {
  CANCELED = "CANCELED",
  DELIVERED = "DELIVERED",
  PAYED = "PAYED",
  PREPARED = "PREPARED",
  REFUNDED = "REFUNDED",
  VALIDATED = "VALIDATED",
}

export enum PermitedOrderStatus {
  CANCELED = "CANCELED",
  DELIVERED = "DELIVERED",
  PREPARED = "PREPARED",
  REFUNDED = "REFUNDED",
  VALIDATED = "VALIDATED",
}

export enum ProductColor {
  AMBER = "AMBER",
  BLACK = "BLACK",
  BLOND = "BLOND",
  DARK = "DARK",
  RED = "RED",
  ROSE = "ROSE",
  WHITE = "WHITE",
}

export enum ProductKind {
  BEER = "BEER",
  COMPOSED = "COMPOSED",
  SIMPLE = "SIMPLE",
  SPIRITUOUS = "SPIRITUOUS",
  WINE = "WINE",
}

export enum ProductPicto {
  BIO = "BIO",
  BIODYNAMICS = "BIODYNAMICS",
  NATURE = "NATURE",
  REASONED = "REASONED",
  VEGAN = "VEGAN",
}

export enum ProductWhiteWineType {
  DRY = "DRY",
  SEMI_DRY = "SEMI_DRY",
  SOFT = "SOFT",
  SWEET = "SWEET",
}

export enum ProductWineType {
  EFFERVESCENT = "EFFERVESCENT",
  STILL = "STILL",
}

export enum Role {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
}

export interface CreateDiscountInput {
  shopId: string;
  unit: DiscountUnit;
  couponCode: string;
  value: number;
  minimumOrderValue: number;
  maximumDiscountAmount?: number | null;
  validFrom: any;
  validUntil?: any | null;
  active: boolean;
}

export interface CreateProductInput {
  shopId: string;
  variantId?: string | null;
  name: string;
  variantName: string;
  crossedPrice?: number | null;
  price: number;
  tax: number;
  stock?: number | null;
  kind: ProductKind;
  color?: ProductColor | null;
  pictos?: ProductPicto[] | null;
  producer: string;
  country: string;
  region?: string | null;
  family?: string | null;
  wineType?: ProductWineType | null;
  whiteWineType?: ProductWhiteWineType | null;
  beerType?: string | null;
  designation?: string | null;
  description?: string | null;
  tastingNote?: string | null;
  active?: boolean | null;
  image: any;
}

export interface OrderSettingsInput {
  allowOutOfStockProducts: boolean;
}

export interface UpdateDiscountInput {
  unit: DiscountUnit;
  couponCode: string;
  value: number;
  minimumOrderValue: number;
  maximumDiscountAmount?: number | null;
  validFrom: any;
  validUntil?: any | null;
  active: boolean;
}

export interface UpdateProductInput {
  variantId?: string | null;
  name: string;
  variantName: string;
  crossedPrice?: number | null;
  price: number;
  tax: number;
  stock?: number | null;
  kind: ProductKind;
  color?: ProductColor | null;
  pictos?: ProductPicto[] | null;
  producer: string;
  country: string;
  region?: string | null;
  family?: string | null;
  wineType?: ProductWineType | null;
  whiteWineType?: ProductWhiteWineType | null;
  beerType?: string | null;
  designation?: string | null;
  description?: string | null;
  tastingNote?: string | null;
  active?: boolean | null;
  image?: any | null;
}

export interface UpdateShopAddressInput {
  street: string;
  city: string;
  postCode: string;
}

export interface UpdateShopHomeDeliveryInput {
  price: number;
  minDaysDelay: number;
  maxDaysDelay: number;
  active: boolean;
}

export interface UpdateShopImagesInput {
  uri?: string | null;
  file?: any | null;
}

export interface UpdateShopInput {
  name?: string | null;
  address?: UpdateShopAddressInput | null;
  phoneNumber?: string | null;
  instagramUri?: string | null;
  facebookUri?: string | null;
  websiteUri?: string | null;
  email?: string | null;
  schedule?: string | null;
  description?: string | null;
  termsOfSale?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
