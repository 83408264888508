import React, { useLayoutEffect } from 'react'
import { Switch, useLocation, useHistory } from 'react-router-dom'
import { useSession } from '../../../core/contexts/session'

import Dashboard from '../layout/Dashboard'
import RouteLayout from './RouteLayout'
import OrdersPage from '../../../pages/orders/OrdersPage'
import ProductsPage from '../../../pages/products/ProductsPage'
import ProductPage from '../../../pages/products/ProductPage'
import DiscountsPage from '../../../pages/discounts/DiscountsPage'
import DiscountPage from '../../../pages/discounts/DiscountPage'
import ShopSettingsPage from '../../../pages/shops/ShopSettingsPage'
import { ShopsProvider } from '../../../core/contexts/shops'
import { useLazyGetAccount } from '../../../core/models/account/hooks'
import Loading from '../../components/layout/Loading'
import OrderPage from '../../../pages/orders/OrderPage'

export default function AuthenticatedRouter() {
  const location = useLocation()
  const history = useHistory()
  const { isAuthenticated } = useSession()
  const [getAccount, { loading, data, error }] = useLazyGetAccount()

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      history.push({
        pathname: '/sign-in',
        state: { from: location },
      })
    } else {
      getAccount()
    }
  }, [isAuthenticated, history, location, getAccount])

  if (loading || typeof data === 'undefined') {
    return <Loading />
  }

  if (error) {
    // TODO: Update this to go to an error page
    return <div>Something wrong happened</div>
  }

  return (
    <ShopsProvider availableShops={data.account.scope.shopIds}>
      <Switch>
        <RouteLayout exact component={OrdersPage} layout={Dashboard} path="/orders" />
        <RouteLayout exact component={OrderPage} layout={Dashboard} path="/orders/:slug" />
        <RouteLayout exact component={ProductsPage} layout={Dashboard} path="/products" />
        <RouteLayout exact component={DiscountsPage} layout={Dashboard} path="/discounts" />
        <RouteLayout
          exact
          component={DiscountPage}
          layout={Dashboard}
          path="/discounts/:slug"
        />
        <RouteLayout
          exact
          component={ProductPage}
          layout={Dashboard}
          path="/products/:slug"
        />
        <RouteLayout
          exact
          component={ShopSettingsPage}
          layout={Dashboard}
          path="/shops/settings"
        />
      </Switch>
    </ShopsProvider>
  )
}
