import React from 'react'
import { useHistory } from 'react-router-dom'

import { DiscountDetails } from '../../../core/models/discount/__generated__/DiscountDetails'
import { DiscountUnit } from '../../../core/models/__generated__/globalTypes'
import { renderDiscountPeriod } from '../../../core/models/discount/utils'

export default function DiscountRow(props: DiscountRowProps) {
  const history = useHistory()
  const { discount } = props

  return (
    <tr>
      <td className="px-6 py-4 whitespace-no-wrap">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm leading-5 font-medium text-gray-900">
              {discount.couponCode}
            </div>
            <div className="text-sm leading-5 text-gray-500">
              {`${discount.value} ${
                discount?.unit === DiscountUnit.EURO ? '€' : '%'
              } de remise sur la commande`}
            </div>
          </div>
        </div>
      </td>
      <td className="px-4 py-4 whitespace-no-wrap">
        <div className="text-sm leading-5 font-medium text-gray-900">
          {renderDiscountPeriod(discount)}
        </div>
      </td>
      <td className="px-4 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        <span className="text-cool-gray-900 font-medium">
          {discount.minimumOrderValue}€{' '}
        </span>
      </td>
      <td className="px-4 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        <span className="text-cool-gray-900 font-medium">{discount.usageCounter}</span>
      </td>
      <td className="px-4 py-4 whitespace-no-wrap">
        {discount.active ? (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
            Actif
          </span>
        ) : (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 text-gray-900">
            Inactif
          </span>
        )}
      </td>
      <td className="px-4 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
        <div
          className="text-purple-600 hover:text-purple-900 cursor-pointer"
          onClick={() => history.push(`/discounts/${discount.id}`)}
        >
          → Voir
        </div>
      </td>
    </tr>
  )
}

interface DiscountRowProps {
  discount: DiscountDetails
}
