import React from 'react'
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone'
import classNames from 'classnames'

export default function ImageField(props: ImageFieldProps) {
  const {
    error,
    name,
    label,
    required,
    image,
    information,
    onDrop,
    disabled = false,
  } = props

  const dropzone = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'image/jpeg, image/png',
    disabled: disabled,
  })

  return (
    <>
      {label && (
        <label htmlFor={name} className="block text-sm leading-5 font-medium text-gray-700">
          {label} {required && '*'}
        </label>
      )}
      <div
        {...dropzone.getRootProps({
          className: classNames(
            'dropzone',
            'mt-2 flex px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md justify-center h-full items-center',
            !!dropzone.isDragActive && ' border-purple-600',
            !!(dropzone.isDragReject || error) && 'border-red-600',
          ),
        })}
      >
        <input name={name} {...dropzone.getInputProps()} />
        {!image.uri ? (
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="mt-1 text-sm text-gray-600">
              <button
                type="button"
                className="font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
              >
                Choisir un fichier
              </button>
              {' ou faire un drag-and-drop'}
            </p>
            <p className="mt-1 text-xs text-gray-500">PNG, JPG, max 5MB</p>
          </div>
        ) : (
          <div className="flex items-center">
            <img
              className="inline-block h-20 w-20 rounded-md shadow-sm object-cover"
              src={image.uri}
              alt={image?.file?.name || '-'}
            />
            <span className="ml-5">
              <button
                type="button"
                hidden={disabled}
                className="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
              >
                Modifier
              </button>
              <p className="mt-1 text-xs text-gray-500">PNG, JPG, max 5MB</p>
            </span>
          </div>
        )}
      </div>
      <p className="mt-2 text-sm text-gray-500">{information}</p>
      {!!error && (
        <p className="mt-1 text-sm text-red-600" id={`${name}-error`}>
          {error.file ? error.file.message : error.uri ? error.uri.message : error.message}
        </p>
      )}
    </>
  )
}

interface ImageFieldProps {
  label?: string
  required?: boolean
  name: string
  image: {
    file?: File | null
    uri?: string
  }
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void
  error?: { message?: string; file?: { message?: string }; uri?: { message?: string } }
  information?: string
  disabled?: boolean
}
