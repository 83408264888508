import React, { useState } from 'react'
import className from 'classnames'
import { Maybe } from '../../../core/types'
import { importProduct, ImportedProduct } from '../../../core/models/product/utils'
import { useShopsContext } from '../../../core/contexts/shops'
import MultiplePricesModal from './MultiplePricesModal'
import { useAlerts } from '../../../core/contexts/alerts'

export default function LoadProductButton(props: LoadProductButtonProps) {
  const { variantId, onLoad, disabled } = props
  const { currentShop } = useShopsContext()
  const { pushErrorAlert } = useAlerts()

  const [open, setOpen] = useState(false)

  const [{ loading, data, error }, setState] = useState<{
    loading: boolean
    data: Maybe<ImportedProduct>
    error: Maybe<string>
  }>({
    loading: false,
    data: null,
    error: null,
  })

  async function onClickHandler() {
    if (!loading && variantId) {
      setState({ loading: true, data: null, error: null })
      const { success, data, error } = await importProduct(variantId, currentShop)
      if (success && typeof data !== 'undefined') {
        setState({ loading: false, data, error: null })
        if (data.prices.length > 1) {
          setOpen(true)
        } else {
          onLoad({ ...data, price: data.prices.length > 0 ? data.prices[0].value : 0 })
        }
      } else {
        setState({ loading: false, data: null, error: error ?? 'something wrong happened' })
        if (error && error.message === 'Product is bulk') {
          pushErrorAlert(
            'Nous sommes désolés, mais vous ne pouvez pas importer de produits en vrac',
          )
        }
      }
    }
  }

  return (
    <>
      {data && (
        <MultiplePricesModal
          prices={data.prices}
          open={open}
          onChoice={(price) => {
            setOpen(false)
            onLoad({ ...data, price })
          }}
        />
      )}
      <button
        type="button"
        disabled={disabled}
        className={className(
          'inline-flex justify-items-center items-center py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out shadow-sm',
          error && 'bg-red-50 border-red-300 text-red-800 hover:text-red-500',
          data && 'bg-green-50 border-green-300 text-green-800 hover:text-green-500',
          disabled && 'opacity-50 cursor-not-allowed',
        )}
        onClick={onClickHandler}
      >
        {data && (
          <svg
            className="flex-shrink-0 mr-1.5 h-4 w-4 text-grey-300"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {error && (
          <svg
            className="flex-shrink-0 mr-1.5 h-4 w-4 text-grey-300"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.4766 14.8907C12.4958 15.5892 11.2959 16 10 16C6.68629 16 4 13.3137 4 10C4 8.70414 4.41081 7.50423 5.1093 6.52339L13.4766 14.8907ZM14.8908 13.4765L6.52354 5.1092C7.50434 4.41077 8.7042 4 10 4C13.3137 4 16 6.68629 16 10C16 11.2958 15.5892 12.4957 14.8908 13.4765ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10Z"
              fill="currentColor"
            />
          </svg>
        )}
        <svg
          className={className(
            'animate-spin -ml-1 mr-3 h-4 w-4 text-grey-800',
            !loading && 'hidden',
          )}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        Auto-compléter
      </button>
    </>
  )
}

interface LoadProductButtonProps {
  variantId?: string | null
  disabled?: boolean
  onLoad: (data: Omit<ImportedProduct, 'price'> & { price: number }) => void
}
