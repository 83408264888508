import React, { useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import useQueryParams from '../../core/hooks/useQueryParams'
import { useSession } from '../../core/contexts/session'
import Button from '../../lib/components/buttons/Button'
import BannerError from '../../lib/components/banners/BannerError'
import StringField from '../../lib/components/fields/StringField'

type FormSchema = {
  email: string
  password: string
  passwordConfirm: string
  shopName: string
  xlsoftCode: string
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FormSchema = Yup.object().shape<FormSchema>({
  email: Yup.string().required('Ce champ est requis'),
  password: Yup.string()
    .required('Ce champ est requis')
    .min(8, 'Le mot de passe doit comporter au moins 8 caractères'),
  passwordConfirm: Yup.mixed<string>().oneOf(
    [Yup.ref('password')],
    'Les mots de passe ne correspondent pas. Essayez à nouveau.',
  ),
  shopName: Yup.string().required('Ce champ est requis'),
  xlsoftCode: Yup.string().required('Ce champ est requis'),
})

export default function SignUpPage() {
  const history = useHistory()
  const { inviteCode } = useQueryParams(['inviteCode'])
  const { signUp } = useSession()
  const [messageError, setMessageError] = useState<string>()
  const { register, errors, handleSubmit, formState } = useForm<FormSchema>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
  })
  const { isSubmitting } = formState

  useLayoutEffect(() => {
    if (!inviteCode) {
      history.push('/')
    }
  }, [inviteCode])

  async function onSubmit({ shopName, xlsoftCode, email, password }: FormSchema) {
    const [success, error] = await signUp(
      shopName,
      xlsoftCode,
      email,
      inviteCode!,
      password,
    )

    setMessageError(() => '')

    if (success) {
      history.push('/orders')
    } else if (error) {
      switch (error.error?.message) {
        case 'Sign Up Invite code is wrong': {
          setMessageError(() => 'Votre code d’invitation est incorrect ou périmé')
          break
        }
        case 'Email already exists': {
          setMessageError(() => 'Votre compte est déjà créé')
          break
        }
        case 'Could not create shop products': {
          setMessageError(() => 'Votre code de magasin est incorrect')
          break
        }
        default:
          setMessageError(() => "Quelque chose s'est mal passé, veuillez nous contacter")
      }
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div className="mb-6">
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Créez votre compte Potico
          </h2>
        </div>
        {!!messageError && <BannerError message={messageError} />}
        <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm">
            <div>
              <StringField
                label="Nom du magasin"
                register={register}
                ariaLabel="Nom du magasin"
                name="shopName"
                type="text"
                placeholder="Nom du magasin"
                error={errors.email}
              />
            </div>
            <div className="mt-2">
              <StringField
                label="Email"
                register={register}
                ariaLabel="Email address"
                name="email"
                type="email"
                placeholder="hello@wino.fr"
                error={errors.email}
              />
            </div>
            <div className="mt-2">
              <StringField
                label="Mot de passe"
                register={register}
                ariaLabel="Password"
                name="password"
                type="password"
                placeholder="****"
                error={errors.password}
              />
            </div>
            <div className="mt-2">
              <StringField
                label="Confirmer votre mot de passe"
                register={register}
                ariaLabel="Password"
                name="passwordConfirm"
                type="password"
                placeholder="****"
                error={errors.passwordConfirm}
              />
            </div>
            <div className="mt-2">
              <StringField
                label="Code du magasin"
                register={register}
                ariaLabel="Code du magasin"
                name="xlsoftCode"
                type="text"
                placeholder="Code du magasin"
                error={errors.xlsoftCode}
              />
            </div>
          </div>
          <div className="mt-6">
            <Button type="submit" disabled={isSubmitting}>
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {!isSubmitting && (
                  <svg
                    className="h-5 w-5 text-purple-500 group-hover:text-purple-700 transition ease-in-out duration-150"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                {isSubmitting && (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                )}
              </span>
              Créer le compte
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
