import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useChangeOrderStatus, useOrder } from '../../core/models/order/hooks'
import Loading from '../../lib/components/layout/Loading'
import {
  renderOrderPaymentStatus,
  renderOrderStatus,
} from '../../lib/containers/orders/OrderRow'
import { PermitedOrderStatus } from '../../core/models/__generated__/globalTypes'
import { useAlerts } from '../../core/contexts/alerts'
import ChangeStatusDropDown from '../../lib/containers/orders/ChangeStatusDropDown'
import OrderCustomerDetails from '../../lib/containers/orders/OrderCustomerDetails'
import OrderProductsDetails from '../../lib/containers/orders/OrderProductsDetails'
import OrderTimelineDetails from '../../lib/containers/orders/OrderTimelineDetails'

export default function OrderPage() {
  const history = useHistory()
  const { slug } = useParams<{ slug: string }>()
  const { order } = useOrder({ id: slug })
  const { pushSuccessAlert, pushErrorAlert } = useAlerts()
  const { changeOrderStatus } = useChangeOrderStatus({
    onCompleted: () =>
      pushSuccessAlert('Vos changements sont enregistrés et le client en sera informé'),
    onError: () => pushErrorAlert("Une erreur s'est produite, essayez à nouveau"),
  })

  function onChangeStatusHandler(status: PermitedOrderStatus) {
    changeOrderStatus({ id: order?.id as string, status })
  }

  if (typeof order === 'undefined') {
    return <Loading />
  }

  return (
    <div className="pt-2 pb-6 md:py-6 mb-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <button
          // TODO: Fix in case of a copy pasted link this should go back to products/ page instead
          onClick={() => history.goBack()}
          className="font-medium text-purple-600 hover:text-purple-500 transition ease-in-out duration-150"
        >
          &larr; Retour
        </button>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-semibold text-gray-900">Commande N°{order.name}</h1>
          <div>
            {renderOrderStatus(order.status)}
            {renderOrderPaymentStatus(order.status)}
          </div>
        </div>
        <div>
          <ChangeStatusDropDown
            status={order.status}
            onChangeStatus={onChangeStatusHandler}
          />
        </div>
      </div>
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          <OrderProductsDetails order={order} />
          <OrderCustomerDetails order={order} />
        </div>
        <OrderTimelineDetails order={order} />
      </div>
    </div>
  )
}
