import { useCallback } from 'react'
import { useQuery, useMutation, MutationHookOptions } from '@apollo/client'
import { useShopsContext } from '../../contexts/shops'
import { GET_SHOP } from './queries'
import {
  ACTIVATE_SHOP,
  DEACTIVATE_SHOP,
  UPDATE_SHOP,
  UPDATE_SHOP_HOME_DELIVERY,
  UPDATE_SHOP_IMAGES,
  UPDATE_SHOP_ORDER_SETTINGS,
} from './mutations'
import { GetShop, GetShopVariables } from './__generated__/GetShop'
import { UpdateShop, UpdateShopVariables } from './__generated__/UpdateShop'
import { ActivateShop, ActivateShopVariables } from './__generated__/ActivateShop'
import { DeactivateShop, DeactivateShopVariables } from './__generated__/DeactivateShop'
import {
  UpdateShopImages,
  UpdateShopImagesVariables,
} from './__generated__/UpdateShopImages'
import {
  UpdateShopHomeDelivery,
  UpdateShopHomeDeliveryVariables,
} from './__generated__/UpdateShopHomeDelivery'
import {
  UpdateShopOrderSettings,
  UpdateShopOrderSettingsVariables,
} from './__generated__/UpdateShopOrderSettings'

export function useCurrentShop() {
  const { currentShop } = useShopsContext()
  const { loading, data, error } = useQuery<GetShop, GetShopVariables>(GET_SHOP, {
    variables: { id: currentShop },
    fetchPolicy: 'cache-and-network',
  })
  return { loading, error, shop: data?.shop }
}

export function useUpdateShop(
  options?: MutationHookOptions<UpdateShop, UpdateShopVariables>,
) {
  const [mutation, { data, loading }] = useMutation<UpdateShop, UpdateShopVariables>(
    UPDATE_SHOP,
    options,
  )

  const updateShop = useCallback(
    async (variables: UpdateShopVariables) => {
      await mutation({ variables })
    },
    [mutation],
  )

  return { updateShop, shop: data?.updateShop, loading }
}

export function useActivateShop(
  options?: MutationHookOptions<ActivateShop, ActivateShopVariables>,
) {
  const [mutation, { data, loading }] = useMutation<ActivateShop, ActivateShopVariables>(
    ACTIVATE_SHOP,
    options,
  )

  const activateShop = useCallback(
    async (variables: ActivateShopVariables) => {
      await mutation({ variables })
    },
    [mutation],
  )

  return { activateShop, shop: data?.activateShop, loading }
}

export function useDeactivateShop(
  options?: MutationHookOptions<DeactivateShop, DeactivateShopVariables>,
) {
  const [mutation, { data, loading }] = useMutation<
    DeactivateShop,
    DeactivateShopVariables
  >(DEACTIVATE_SHOP, options)

  const deactivateShop = useCallback(
    async (variables: DeactivateShopVariables) => {
      await mutation({ variables })
    },
    [mutation],
  )

  return { deactivateShop, shop: data?.deactivateShop, loading }
}

export function useUpdateShopImages(
  options?: MutationHookOptions<UpdateShopImages, UpdateShopImagesVariables>,
) {
  const [mutation, { data, loading }] = useMutation<
    UpdateShopImages,
    UpdateShopImagesVariables
  >(UPDATE_SHOP_IMAGES, options)

  const updateShopImages = useCallback(
    async (variables: UpdateShopImagesVariables) => {
      await mutation({ variables })
    },
    [mutation],
  )

  return { updateShopImages, shop: data?.updateShopImages, loading }
}

export function useUpdateShopHomeDelivery(
  options?: MutationHookOptions<UpdateShopHomeDelivery, UpdateShopHomeDeliveryVariables>,
) {
  const [mutation, { data, loading }] = useMutation<
    UpdateShopHomeDelivery,
    UpdateShopHomeDeliveryVariables
  >(UPDATE_SHOP_HOME_DELIVERY, options)

  const updateShopHomeDelivery = useCallback(
    async (variables: UpdateShopHomeDeliveryVariables) => {
      await mutation({ variables })
    },
    [mutation],
  )

  return { updateShopHomeDelivery, shop: data?.updateShopHomeDelivery, loading }
}

export function useUpdateShopOrderSettings(
  options?: MutationHookOptions<UpdateShopOrderSettings, UpdateShopOrderSettingsVariables>,
) {
  const [mutation, { data, loading }] = useMutation<
    UpdateShopOrderSettings,
    UpdateShopOrderSettingsVariables
  >(UPDATE_SHOP_ORDER_SETTINGS, options)

  const updateShopOrderSettings = useCallback(
    async (variables: UpdateShopOrderSettingsVariables) => {
      await mutation({ variables })
    },
    [mutation],
  )

  return { updateShopOrderSettings, shop: data?.updateShopOrderSettings, loading }
}
