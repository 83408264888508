import React from 'react'
import classNames from 'classnames'
import DatePickerInput from '../inputs/DatePickerInput'

export default function DatePickerField<T>(props: DatePickerFieldProps<T>) {
  const { label, required, error, name, value, control, minDate, maxDate } = props

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">
        {label} {required && '*'}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <DatePickerInput
          name={name}
          value={value}
          className={classNames(
            'transition duration-150 ease-in-out',
            !!error && 'border-red-600 focus:border-red-300',
          )}
          control={control}
          minDate={minDate}
          maxDate={maxDate}
        />
        <div className="absolute inset-y-0 right-0 pr-1 flex items-center pointer-events-none">
          <svg
            className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-600 group-focus:text-gray-500 transition ease-in-out duration-150"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
      </div>
      {!!error && (
        <p className="mt-1 text-sm text-red-600" id={`${name}-error`}>
          {error.message}
        </p>
      )}
    </>
  )
}

interface DatePickerFieldProps<T> {
  label: string
  required?: boolean
  name: string
  value: T
  error?: { message?: string }
  control: any
  minDate?: Date
  maxDate?: Date
}
