import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import useQueryParams from '../../core/hooks/useQueryParams'
import { useProducts } from '../../core/models/product/hooks'
import TableNavigation from '../../lib/components/tables/TableNavigation'
import Container from '../../lib/components/layout/Container'
import NoProducts from '../../lib/containers/products/NoProducts'
import ProductRow from '../../lib/containers/products/ProductRow'
import Loading from '../../lib/components/layout/Loading'
import { ShopRedirection } from '../../lib/components/redirections/ShopRedirection'
import { useCurrentShop } from '../../core/models/shop/hooks'

const FETCHING_LIMIT = 10

export default function ProductsPage() {
  const history = useHistory()
  const { after, before } = useQueryParams(['before', 'after'])
  const { products } = useProducts({
    ...(after
      ? { first: FETCHING_LIMIT, after }
      : before
      ? { before, last: FETCHING_LIMIT }
      : { first: FETCHING_LIMIT }),
  })
  const { shop, loading } = useCurrentShop()

  const onNextHandler = useCallback(() => {
    history.push(`/products?after=${products?.pageInfo.endCursor}`)
  }, [history, products])

  const onPreviousHandler = useCallback(() => {
    history.push(`/products?before=${products?.pageInfo.startCursor}`)
  }, [history, products])

  if (!products || !shop || loading) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  const { edges } = products

  if (edges.length === 0) {
    return (
      <Container>
        <NoProducts shop={shop} />
      </Container>
    )
  }

  return (
    <Container>
      <div className="pt-2 pb-6 md:py-6">
        <h1 className="text-2xl font-semibold text-gray-900">Produits</h1>
        <div className="pb-5 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
          <p className="mt-1 max-w-xl text-sm leading-5 text-gray-500">
            Lier un maximum de produits de votre choix de votre catalogue{' '}
            <i>Wino la Caisse connectée</i> sur votre plateforme de <i>click-and-collect</i>
            .
          </p>
          <div className="flex space-x-3">
            <span className="shadow-sm rounded-md">
              <ShopRedirection to="shop" label="Voir en ligne" />
            </span>
            <span className="shadow-sm rounded-md">
              {!loading && shop.franchise !== 'Cavavin' && (
                <button
                  onClick={() => history.push('/products/create')}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-purple-700 transition duration-150 ease-in-out"
                >
                  Nouveau
                </button>
              )}
            </span>
          </div>
        </div>
        <div className="py-4">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Référence
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Catégorie
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Prix de vente
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Statut
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Stock
                        </th>
                        <th className="px-4 py-3 bg-gray-50" />
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {edges.map((product) => (
                        <ProductRow key={product.node.id} product={product.node} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableNavigation
          hasNextPage={products.pageInfo.hasNextPage}
          hasPreviousPage={products.pageInfo.hasPreviousPage}
          onNext={onNextHandler}
          onPrevious={onPreviousHandler}
        />
      </div>
    </Container>
  )
}
