import { gql } from '@apollo/client'
import { PRODUCT_DETAILS } from './fragments'

export const GET_PRODUCTS = gql`
  query GetProducts(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $shopId: String!
  ) {
    products(
      first: $first
      last: $last
      before: $before
      after: $after
      where: { shopId: { _in: [$shopId] } }
    ) {
      edges {
        cursor
        node {
          ...ProductDetails
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
  ${PRODUCT_DETAILS}
`

export const GET_PRODUCT = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      ...ProductDetails
    }
  }
  ${PRODUCT_DETAILS}
`
