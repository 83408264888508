import React, { useState } from 'react'
import classNames from 'classnames'
import { ShopDetails } from '../../../core/models/shop/__generated__/ShopDetails'
import ShopChangeStateModal from './ShopChangeStateModal'
import useIntercom from '../../../core/hooks/useIntercom'
import { useAlerts } from '../../../core/contexts/alerts'
import { useShopsContext } from '../../../core/contexts/shops'

function canActivateShop(shop: ShopDetails) {
  const errors = []

  if (
    !shop.email ||
    !shop.schedule ||
    !shop.description ||
    !shop.name ||
    !shop.address?.city ||
    !shop.address?.street ||
    !shop.address?.postCode
  ) {
    errors.push('Certaines des informations publiques de la boutique sont manquantes')
  }

  let termsOfSaleExists = false

  if (shop.termsOfSale) {
    const text = JSON.parse(shop.termsOfSale)
      .map((node: any) => node.children.map((child: any) => child.text).join(''))
      .join('')
    if (typeof text === 'string' && text.length > 0) {
      termsOfSaleExists = true
    }
  }

  if (!termsOfSaleExists) {
    errors.push('Les conditions de vente ne sont pas encore remplies')
  }

  if (!shop.paymentActive) {
    errors.push("Le compte de paiement de la boutique n'est pas encore configuré")
  }

  return { errors }
}

export default function WebsiteSettingsForm(props: WebsiteSettingsFormProps) {
  const { shop, onActivateShop, onDeactivateShop } = props
  const intercom = useIntercom()
  const { pushErrorAlert } = useAlerts()
  const [open, setOpen] = useState(false)
  const { createPaymentAccount } = useShopsContext()
  const active = shop.active === true
  const paymentActive = shop.paymentActive === true

  function onValidateHandler() {
    if (active) {
      onDeactivateShop()
    } else {
      const { errors } = canActivateShop(shop)
      if (errors.length > 0) {
        pushErrorAlert(
          <>
            <h3 className="text-sm font-medium text-red-800">
              La boutique n'est pas encore prête à devenir active, veuillez corriger les
              informations suivantes
            </h3>
            <ul className="list-disc pl-5 space-y-1">
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </>,
        )
      } else {
        onActivateShop()
      }
    }
    setOpen(false)
  }

  async function onAddStripeAccount() {
    const [data] = await createPaymentAccount(shop.id)
    if (data && data.url) {
      window.location.href = data.url
    }
  }

  return (
    <>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Statut de la boutique
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              {active
                ? 'Les internautes peuvent naviguer sur votre page et acheter vos produits.'
                : 'Une fois la boutique activée, les internautes pourront naviguer sur votre page et acheter vos produits.'}
            </p>
          </div>
          {active && (
            <p className="mt-3 flex items-center text-sm text-gray-500">
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Boutique en ligne activée
            </p>
          )}
          <div className="mt-4">
            <button
              onClick={() => setOpen(true)}
              type="button"
              className={classNames(
                'inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm cursor-pointer',
                active && 'text-red-500 bg-red-300  hover:bg-red-200 focus:ring-red-500',
                !active &&
                  'text-green-700 bg-green-300  hover:bg-green-200 focus:ring-green-500',
              )}
            >
              {active ? 'Désactiver la boutique' : 'Activer la boutique'}
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 min-w-max">
            Statut du paiement
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500 ">
            <p>
              {paymentActive
                ? 'Votre compte Stripe de paiement est configuré et prêt'
                : 'Vous devez configurer un compte de paiement Stripe.'}
            </p>
          </div>
          <p className="mt-3 flex items-center text-sm text-gray-500">
            <svg
              className={`flex-shrink-0 mr-1.5 h-5 w-5 text-${
                paymentActive ? 'green' : 'gray'
              }-400`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            {paymentActive ? 'Activé' : 'Désactivé'}
          </p>
          {!paymentActive && (
            <div>
              {shop.franchise === 'Cavavin' && (
                <div className="mt-4">
                  <button
                    onClick={onAddStripeAccount}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Créer un compte de paiement
                  </button>
                </div>
              )}
              <div className="mt-4">
                <button
                  onClick={intercom.show}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Contactez-nous
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ShopChangeStateModal
        open={open}
        active={active}
        onCancel={() => setOpen(false)}
        onValidate={onValidateHandler}
      />
    </>
  )
}

interface WebsiteSettingsFormProps {
  shop: ShopDetails
  onActivateShop: () => void
  onDeactivateShop: () => void
}
