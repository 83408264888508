import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'

import StringField from '../../components/fields/StringField'
import TextField from '../../components/fields/TextField'
import SelectField from '../../components/fields/SelectField'
import PrefixStringField from '../../components/fields/PrefixStringField'
import NumberField from '../../components/fields/NumberField'
import ImageField from '../../components/fields/ImageField'
import Button from '../../components/buttons/Button'
import MultiselectMenu from '../../components/menus/MultiSelectMenu'

import { GetProduct_product } from '../../../core/models/product/__generated__/GetProduct'
import {
  pictosList,
  kinds,
  wineColors,
  beerColors,
  activeStates,
  wineTypes,
  whiteWineTypes,
  taxes,
  ImportedProduct,
  getPictoColorFromLabel,
} from '../../../core/models/product/utils'
import {
  CreateProductInput,
  ProductPicto,
  ProductColor,
  ProductWhiteWineType,
  ProductWineType,
  UpdateProductInput,
} from '../../../core/models/__generated__/globalTypes'
import { ProductKind } from '../../../core/models/__generated__/globalTypes'
import { Maybe } from '../../../core/types'

import LoadProductButton from './LoadProductButton'
import Hidden from '../../components/layout/Hidden'
import { ShopRedirection } from '../../components/redirections/ShopRedirection'

type FormSchema = {
  variantId?: string | null
  active: boolean
  name: string
  variantName: string
  producer: string
  country: string
  region?: string
  family?: string
  wineType?: string
  beerType?: string
  designation?: string
  description: string
  crossedPrice?: string
  price: string
  beerColor?: Maybe<string>
  pictos?: Maybe<string[]>
  wineColor?: Maybe<string>
  whiteWineType?: Maybe<string>
  kind: string
  tax: string
  stock?: Maybe<string>
  tastingNote?: Maybe<string>
  image: { uri?: string; file?: Maybe<File> }
}

const formFieldConfig = { shouldValidate: true, shouldDirty: true }

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
const FILE_SIZE = 5242880 // 5MB

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FormSchema = Yup.object().shape<FormSchema>({
  variantId: Yup.string().matches(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    { message: 'Champ invalide', excludeEmptyString: true },
  ),
  active: Yup.boolean()
    .oneOf(
      activeStates.map((state) => state.value),
      'Ce champ est requis',
    )
    .required('Ce champ est requis'),
  kind: Yup.mixed<string>()
    .oneOf(
      kinds.map((kind) => kind.value),
      'Ce champ est requis',
    )
    .required('Ce champ est requis'),
  beerColor: Yup.mixed<string>().oneOf(
    beerColors.map((color) => color.value),
    'Ce champ est requis',
  ),
  wineColor: Yup.mixed<string>().oneOf(
    wineColors.map((color) => color.value),
    'Ce champ est requis',
  ),
  name: Yup.string().required('Ce champ est requis').min(3, 'Minimum 3 caractères'),
  variantName: Yup.string().required('Ce champ est requis').min(3, 'Minimum 3 caractères'),
  country: Yup.string().required('Ce champ est requis').min(3, 'Minimum 3 caractères'),
  producer: Yup.string().required('Ce champ est requis').min(3, 'Minimum 3 caractères'),
  region: Yup.string(),
  family: Yup.string(),
  wineType: Yup.mixed<string>().oneOf(
    wineTypes.map((type) => type.value),
    'Ce champ est requis',
  ),
  whiteWineType: Yup.mixed<string>().oneOf(
    whiteWineTypes.map((type) => type.value),
    'Ce champ est requis',
  ),
  pictos: Yup.lazy((val) =>
    Array.isArray(val)
      ? Yup.array().of(Yup.mixed<string>().oneOf(pictosList.map((type) => type.value)))
      : Yup.string(),
  ),
  beerType: Yup.string(),
  designation: Yup.string(),
  description: Yup.string(),
  price: Yup.string()
    .required('Ce champ est requis')
    .test('valid-price', 'Prix non valide', (value) => Number(value) > 0),
  crossedPrice: Yup.string()
    .test({
      name: 'crossedPrice',
      exclusive: true,
      params: {},
      message: 'Le prix réduit doit être inférieur au prix de vente',
      test: function (value) {
        if (value === null || value === undefined || value === '') {
          return true
        } else if (value) {
          return Number(value) < Number(this.parent.price ? this.parent.price : 0)
        } else return false
      },
    })
    .notRequired(),
  stock: Yup.string().test(
    'valid-stock',
    'Valeur de stock invalide',
    (value) => typeof Number(value) === 'number',
  ),
  tax: Yup.string()
    .oneOf(
      taxes.map((tax) => String(tax.value)),
      'Ce champ est requis',
    )
    .required('Ce champ est requis'),
  tastingNote: Yup.string().nullable(),
  // @ts-expect-error the sub-object "file" file is not well typed
  image: Yup.object().shape({
    uri: Yup.string().required('Ce champ est requis'),
    file: Yup.mixed()
      .nullable()
      .test('fileSize', 'Poids de fichier trop important (max 5MB)', (value) =>
        typeof value?.size === 'number' ? value.size <= FILE_SIZE : true,
      )
      .test('fileType', 'Format de fichier non supporté', (value) =>
        typeof value?.type === 'string' ? SUPPORTED_FORMATS.includes(value?.type) : true,
      ),
  }),
})

export default function ProductForm(props: ProductFormProps) {
  const { product, onSave } = props

  const initialValues = {
    variantId: product?.variantId,
    name: product?.name ?? '',
    variantName: product?.variantName ?? '',
    producer: product?.producer ?? '',
    country: product?.country ?? '',
    pictos: product?.pictos ?? [],
    region: product?.region ?? '',
    family: product?.family ?? '',
    wineType: product?.wineType ?? wineTypes[0].value,
    whiteWineType: product?.whiteWineType ?? whiteWineTypes[0].value,
    beerType: product?.beerType ?? '',
    designation: product?.designation ?? '',
    active: product?.active ?? true,
    kind: product?.kind ?? kinds[0].value,
    beerColor:
      product?.kind === ProductKind.BEER
        ? product?.color ?? beerColors[0].value
        : beerColors[0].value,
    wineColor:
      product?.kind === ProductKind.WINE
        ? product?.color ?? wineColors[0].value
        : wineColors[0].value,
    price: product?.price ? String(product?.price) : '',
    crossedPrice: product?.crossedPrice ? String(product?.crossedPrice) : undefined,
    stock: product?.stock ? String(product?.stock) : '',
    tax: product?.tax ? String(product?.tax) : String(taxes[0].value),
    tastingNote: product?.tastingNote ?? '',
    description: product?.description ?? '',
    image: { uri: product?.imageUri, file: void 0 },
  }

  if (initialValues.crossedPrice) {
    const price = initialValues.price
    initialValues.price = initialValues.crossedPrice
    initialValues.crossedPrice = price
  }

  const isEditable = product?.editable ?? true

  useEffect(() => {
    if (typeof initialValues.image.uri === 'string') {
      register('image')
    }
  })

  const { register, watch, handleSubmit, errors, setError, clearErrors, reset, setValue } =
    useForm<FormSchema>({
      mode: 'onTouched',
      resolver: yupResolver(FormSchema),
      defaultValues: initialValues,
    })

  const {
    image,
    variantId,
    kind,
    pictos,
    wineColor,
    wineType,
    active,
    beerColor,
    tax,
    whiteWineType,
    crossedPrice,
    price,
  } = watch([
    'image',
    'variantId',
    'kind',
    'pictos',
    'wineColor',
    'wineType',
    'beerColor',
    'active',
    'tax',
    'whiteWineType',
    'crossedPrice',
    'price',
  ])

  function onSubmitHandler(data: FormSchema) {
    console.log(data)
    const stock = Number(data.stock)
    const kind: ProductKind = data.kind as ProductKind
    const pictos = data.pictos as ProductPicto[]
    const beerColor = data.beerColor
    const wineColor = data.wineColor
    delete data.beerColor
    delete data.wineColor
    let color: Maybe<string> | undefined = undefined

    if (kind === ProductKind.WINE) {
      color = wineColor
    } else if (kind === ProductKind.BEER) {
      color = beerColor
    }

    const result: Omit<CreateProductInput | UpdateProductInput, 'shopId'> = {
      ...data,
      variantId: data.variantId === '' ? undefined : data.variantId,
      price:
        data.crossedPrice && data.crossedPrice < data.price
          ? Number(data.crossedPrice)
          : Number(data.price),
      crossedPrice:
        data.crossedPrice && data.crossedPrice < data.price ? Number(data.price) : null,
      tax: Number(data.tax),
      kind,
      pictos,
      color: color as ProductColor,
      wineType: data.wineType as ProductWineType,
      whiteWineType: data.whiteWineType as ProductWhiteWineType,
      stock: data.variantId === '' || !isEditable ? stock : undefined,
      ...(data.image ? { image: data.image.file } : {}),
    }

    if (kind !== ProductKind.WINE) {
      delete result.wineType
      delete result.region
      delete result.designation
    }
    if (kind !== ProductKind.BEER) {
      delete result.beerType
    }
    if (kind !== ProductKind.SPIRITUOUS) {
      delete result.family
    }
    if (![ProductKind.WINE, ProductKind.BEER].includes(kind)) {
      delete result.color
    }
    if (!(kind === ProductKind.WINE && wineColor === ProductColor.WHITE)) {
      delete result.whiteWineType
    }

    onSave(result)
  }

  function onResetHandler(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    reset(initialValues)
    setValue('image', initialValues.image, formFieldConfig)
  }

  function onDropHandler(acceptedFiles: File[]) {
    const file = acceptedFiles[0]
    if (file) {
      register('image')
      setValue('image', { uri: URL.createObjectURL(file), file }, formFieldConfig)
    }
  }

  function setFormValue<T>(key: string, value?: Maybe<T>) {
    if (typeof value !== 'undefined' && value !== null) {
      setValue(key, value, formFieldConfig)
    }
  }

  function onLoad(data: Omit<ImportedProduct, 'price'> & { price: number }) {
    setFormValue('name', data.name)
    setFormValue('kind', data.kind)
    setFormValue('tax', data.tax)
    setFormValue('price', data.price)
    setFormValue('description', data.description)
    setFormValue('variantName', data.variantName)
    setFormValue('tastingNote', data.tastingNote)
    setFormValue('producer', data.producer)
    setFormValue('country', data.country)
    if (data.kind === ProductKind.WINE) {
      setFormValue('whiteWineType', data.whiteWineType)
      setFormValue('wineType', data.wineType)
      setFormValue('wineColor', data.color)
    }
    if (data.kind === ProductKind.BEER) {
      setFormValue('beerType', data.beerType)
      setFormValue('beerColor', data.color)
    }
    setFormValue('family', data.family)
    setFormValue('designation', data.designation)
    setFormValue('region', data.region)
    setFormValue('stock', data.stock)
  }
  const [isHavingCrossedPrice, setIsHavingCrossedPrice] = useState(
    crossedPrice !== undefined,
  )

  function handleClickButtonSwitch() {
    setIsHavingCrossedPrice(!isHavingCrossedPrice)

    if (!isHavingCrossedPrice === false) {
      setValue('crossedPrice', null)
      clearErrors(['crossedPrice'])
    } else {
      setValue('crossedPrice', price)
      setError('crossedPrice', {
        type: 'manual',
        message: 'Le prix réduit doit être inférieur au prix de vente',
      })
    }
  }

  function setPictosValues(items: ProductPicto[]) {
    register({ name: 'pictos' })
    setValue('pictos', items)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="pb-5 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
          {typeof product === 'object' ? (
            <p className="mt-1 max-w-xl text-sm leading-5 text-gray-500">
              Mettez à jour votre produit pour augmenter vos ventes : affinez votre prix de
              vente, retravaillez la photo de votre produit ou de votre note de dégustation.
            </p>
          ) : (
            <p className="mt-1 max-w-xl text-sm leading-5 text-gray-500">
              Ajoutez un produit de votre catalogue Wino la Caisse connectée pour compléter
              automatiquement les champs et connecter les stocks. Vous pouvez également
              ajouter un produit manuellement.{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="http://help.wino.fr/fr/articles/4654541-creer-son-catalogue-pour-le-click-and-collect"
                className="underline hover:no-underline"
              >
                Comment compléter ma fiche produit ?
              </a>
            </p>
          )}
          <div className="flex space-x-3">
            {product && product.active === true && (
              <span className="inline-flex rounded-md shadow-sm">
                <ShopRedirection to="product" id={product.id} label="Voir en ligne" />
              </span>
            )}
            <span className="shadow-sm rounded-md">
              <button
                onClick={onResetHandler}
                className="inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
              >
                Annuler
              </button>
            </span>
            <span className="shadow-sm rounded-md">
              <Button type="submit">Enregistrer</Button>
            </span>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 grid grid-cols-3 gap-x-4">
        <div className="mt-6 sm:col-span-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
          {/* VariantId */}
          <div className="sm:col-span-2">
            <div className="grid gap-x-2 grid-cols-7">
              <div className="col-span-5">
                <PrefixStringField
                  register={register}
                  label="Identifiant"
                  prefix="dashboard.wino.fr/"
                  name="variantId"
                  disabled={!isEditable}
                />
              </div>
              <div className="mt-6 col-span-2">
                <span className="ml-2 self-center relative" style={{ top: '0.125re' }}>
                  <LoadProductButton
                    disabled={!variantId || !isEditable}
                    variantId={variantId}
                    onLoad={onLoad}
                  />
                </span>
              </div>
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Copier/Coller l'identifiant (ID) d'un produit de Wino la Caisse connectée.{' '}
              <a
                rel="noreferrer"
                href="http://help.wino.fr/fr/articles/4664012-copier-l-id-de-votre-reference-produit-wino-pour-le-coller-dans-potico"
                target="_blank"
                className="underline hover:no-underline"
              >
                Comment faire ?
              </a>
            </p>
          </div>
          {/* Statut */}
          <div className="sm:col-span-2">
            <SelectField
              register={register}
              label="Statut"
              required={true}
              name="active"
              value={active}
              options={activeStates}
              error={errors.active}
            />
          </div>
          {/* Kind */}
          <div className="sm:col-span-2">
            <SelectField
              register={register}
              label="Type de produit"
              required={true}
              name="kind"
              options={kinds}
              value={kind}
              error={errors.kind}
              readOnly={!isEditable}
            />
          </div>
          {/* Name */}
          <div className="sm:col-span-1">
            <StringField
              register={register}
              label="Nom du produit"
              required={true}
              name="name"
              error={errors.name}
              readOnly={!isEditable}
            />
          </div>
          {/* Variant Name */}
          <div className="sm:col-span-1">
            <StringField
              register={register}
              label="Nom de la variante"
              information="Millésimé et/ou contenance/poids"
              required={true}
              name="variantName"
              error={errors.variantName}
              readOnly={!isEditable}
            />
          </div>
          {/* Picto */}
          <div className="sm:col-span-1">
            <MultiselectMenu
              label="Label"
              initialSelectedItems={pictosList
                .filter((x) => pictos?.includes(x.value))
                .map((y) => y.label)}
              items={pictosList.map((x) => x.label)}
              onChange={(item) =>
                setPictosValues(
                  pictosList.filter((x) => item.includes(x.label))?.map((y) => y.value),
                )
              }
              renderItem={(item, key) => {
                const backgroundColor = getPictoColorFromLabel(item) ?? '#907B1E'
                const color = '#FFFFFF'

                return (
                  <>
                    <span
                      key={`${key}-${item}`}
                      className="m-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                      style={{ backgroundColor, color }}
                    >
                      {item}
                    </span>
                  </>
                )
              }}
              isDisabled={!isEditable}
              placeholder="Aucun"
            />
          </div>
          <div className="sm:col-span-1" />
          {/* Producer */}
          <div className="sm:col-span-1">
            <StringField
              register={register}
              label="Producteur"
              required={true}
              name="producer"
              error={errors.producer}
              readOnly={!isEditable}
            />
          </div>
          {/* Country */}
          <div className="sm:col-span-1">
            <StringField
              register={register}
              label="Pays"
              required={true}
              name="country"
              error={errors.country}
              readOnly={!isEditable}
            />
          </div>
          {/* Wine Color */}
          <Hidden className="sm:col-span-2" hide={kind !== ProductKind.WINE}>
            <SelectField
              register={register}
              label="Couleur"
              required={kind === ProductKind.WINE}
              name="wineColor"
              value={wineColor}
              options={wineColors}
              error={errors.wineColor}
              readOnly={!isEditable}
            />
          </Hidden>
          {/* Beer Color */}
          <Hidden className="sm:col-span-2" hide={kind !== ProductKind.BEER}>
            <SelectField
              register={register}
              label="Couleur"
              required={kind === ProductKind.BEER}
              name="beerColor"
              value={beerColor}
              options={beerColors}
              error={errors.beerColor}
              readOnly={!isEditable}
            />
          </Hidden>
          {/* Win Type */}
          <Hidden
            className={classNames(
              wineColor === ProductColor.WHITE ? 'sm:col-span-1' : 'sm:col-span-2',
            )}
            hide={kind !== ProductKind.WINE}
          >
            <SelectField
              register={register}
              label="Type de vin"
              required={true}
              name="wineType"
              value={wineType}
              options={wineTypes}
              error={errors.wineType}
              readOnly={!isEditable}
            />
          </Hidden>
          {/* Win Type */}
          <Hidden
            className="sm:col-span-1"
            hide={!(kind === ProductKind.WINE && wineColor === ProductColor.WHITE)}
          >
            <SelectField
              register={register}
              label="Type de vin blanc"
              required={true}
              name="whiteWineType"
              value={whiteWineType}
              options={whiteWineTypes}
              error={errors.wineType}
              readOnly={!isEditable}
            />
          </Hidden>
          {/* Beer Type */}
          <Hidden className="sm:col-span-2" hide={kind !== ProductKind.BEER}>
            <StringField
              register={register}
              label="Type de bière"
              name="beerType"
              error={errors.wineType}
              readOnly={!isEditable}
            />
          </Hidden>
          {/* Region */}
          <Hidden className="sm:col-span-1" hide={kind !== ProductKind.WINE}>
            <StringField
              register={register}
              label="Région"
              name="region"
              error={errors.region}
              readOnly={!isEditable}
            />
          </Hidden>
          {/* Designation */}
          <Hidden className="sm:col-span-1" hide={kind !== ProductKind.WINE}>
            <StringField
              register={register}
              label="Appellation"
              name="designation"
              error={errors.designation}
              readOnly={!isEditable}
            />
          </Hidden>
          {/* Family */}
          <Hidden className="sm:col-span-1" hide={kind !== ProductKind.SPIRITUOUS}>
            <StringField
              register={register}
              label="Famille"
              name="family"
              error={errors.family}
              readOnly={!isEditable}
            />
          </Hidden>
          {kind === ProductKind.SPIRITUOUS && <div className="sm:col-span-1"></div>}
          {/* Price */}
          <div className="sm:col-span-1">
            <NumberField
              register={register}
              name="price"
              label="Prix de vente TTC"
              required={true}
              min={0.0}
              max={10000.0}
              step={0.01}
              placeholder="0.0"
              unit="EUR (€)"
              error={errors.price}
              readOnly={!isEditable}
            />
          </div>
          {/* TVA */}
          <div className="sm:col-span-1">
            <SelectField
              register={register}
              label="TVA"
              required={true}
              name="tax"
              value={Number(tax)}
              options={taxes}
              error={errors.tax}
              readOnly={!isEditable}
            />
          </div>
          {/* crossedPrice */}
          <div className="sm:col-span-1 inline-block	">
            <NumberField
              register={register}
              name="crossedPrice"
              label="Prix remisé TTC"
              required={true}
              min={0.0}
              max={10000.0}
              step={0.01}
              placeholder="0.0"
              unit="EUR (€)"
              error={errors.crossedPrice}
              readOnly={!isHavingCrossedPrice}
            />
          </div>
          <div
            className={classNames(
              'sm:col-span-1 flex items-end ',
              errors.crossedPrice ? 'mb-10' : 'mb-2',
            )}
          >
            <button
              type="button"
              className={classNames(
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                isHavingCrossedPrice ? 'bg-green-400' : 'bg-gray-200',
              )}
              aria-pressed="false"
              aria-labelledby="availability-label"
              onClick={handleClickButtonSwitch}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  'pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200 bg-white item-end border-2',
                  isHavingCrossedPrice ? 'translate-x-full' : 'translate-x-0',
                )}
              ></span>
            </button>
          </div>
          {/* Stock */}
          <div className="sm:col-span-2">
            <NumberField
              register={register}
              name="stock"
              label="Stock"
              required={variantId === ''}
              unit="Unité"
              placeholder="0"
              error={errors.stock}
              readOnly={variantId !== '' || !isEditable}
            />
          </div>
          {/* Description */}
          <div className="sm:col-span-2">
            <TextField
              register={register}
              label="Description"
              name="description"
              information="Pour ajouter des informations supplémentaires à la description du produit."
              rows={3}
              error={errors.description}
              readOnly={!isEditable}
            />
          </div>
          {/* Tasting Note */}
          <div className="sm:col-span-2">
            <TextField
              register={register}
              label="Note de dégustation"
              name="tastingNote"
              rows={3}
              error={errors.tastingNote}
              readOnly={!isEditable}
            />
          </div>
          {/* Image */}
          <div className="sm:col-span-2">
            <ImageField
              name="image"
              label="Photo"
              image={image}
              required={true}
              onDrop={onDropHandler}
              information="Choisissez une photo pour mettre en avant votre produit."
              error={errors.image}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2"></div>
      <div className="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="pt-5 border-t border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-end sm:space-x-4 sm:space-y-0">
          <div className="flex space-x-3">
            <span className="shadow-sm rounded-md">
              <button
                onClick={onResetHandler}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
              >
                Annuler
              </button>
            </span>
            <span className="shadow-sm rounded-md">
              <Button type="submit">Enregistrer</Button>
            </span>
          </div>
        </div>
      </div>
    </form>
  )
}

interface ProductFormProps {
  product?: GetProduct_product
  onSave: (data: Omit<CreateProductInput | UpdateProductInput, 'shopId'>) => void
}
