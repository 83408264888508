import React from 'react'

import { APP_BASE_URI } from '../../../core/constants'
import { useCurrentShop } from '../../../core/models/shop/hooks'

export function ShopRedirection(props: ShopRedirectionProps) {
  const { to, id, label } = props
  const { shop, loading } = useCurrentShop()

  if (loading || typeof shop === 'undefined' || shop.active !== true) {
    return null
  }

  const uriParts = [APP_BASE_URI]
  if (to === 'shop') {
    uriParts.push(shop.id)
  } else if (to === 'product' && typeof id === 'string') {
    uriParts.push(shop.id)
    uriParts.push(id)
  }

  return (
    <button
      onClick={() => window.open(uriParts.join('/'), '_blank')}
      className="inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
    >
      {label}
    </button>
  )
}

interface ShopRedirectionProps {
  to: 'shop' | 'product'
  id?: string
  label: string
}
