import React from 'react'
import { Transition } from '@tailwindui/react'
import { Maybe } from '../../../core/types'

export default function MultiplePricesModal(props: MutiplePricesModalProps) {
  const { open, prices, onChoice } = props

  return (
    <div className="fixed z-10">
      <Transition
        show={open}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    Nous avons trouvé plusieurs prix pour le même produit.
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-gray-500">
                      Pourriez-vous en choisir un pour vos ventes en ligne ?
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <fieldset>
                  <ul
                    className="space-y-6"
                    role="radiogroup"
                    aria-labelledby="radiogroup-label"
                  >
                    {prices.map((price) => (
                      <li
                        key={price.name}
                        id="radiogroup-option-0"
                        tabIndex={0}
                        role="radio"
                        aria-checked="true"
                        className="group relative rounded-lg shadow-sm cursor-pointer focus:outline-none focus:shadow-outline-blue"
                        onClick={() => onChoice(price.value)}
                      >
                        <div className="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 group-focus:border-blue-300 sm:flex sm:justify-between sm:space-x-4">
                          <div className="flex items-center space-x-0">
                            <div className="text-sm leading-5">
                              <p className="block font-medium text-gray-900">
                                {price.name}
                              </p>
                            </div>
                          </div>
                          <div className="mt-2 flex text-sm leading-5 space-x-1 sm:mt-0 sm:block sm:space-x-0 sm:text-right">
                            <div className="font-medium text-gray-900">{price.value} €</div>
                          </div>
                        </div>
                        <div className="border-indigo-500 absolute inset-0 rounded-lg border-2 pointer-events-none"></div>
                      </li>
                    ))}
                  </ul>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}

interface MutiplePricesModalProps {
  prices: {
    name: string
    value: number
    isDefault: Maybe<boolean>
  }[]
  open: boolean
  onChoice: (price: number) => void
}
