import { useCallback } from 'react'
import { DocumentNode, QueryHookOptions, useQuery } from '@apollo/client'

export default function usePagination<T, S>(
  query: DocumentNode,
  key: keyof T,
  options: QueryHookOptions<T, S>,
) {
  const { data, error, loading, fetchMore } = useQuery<T, S>(query, options)

  const loadMore = useCallback(() => {
    // @ts-expect-error since i just test
    const { endCursor, hasNextPage } = data[key].pageInfo

    if (loading || !hasNextPage) {
      return
    }
    fetchMore({
      variables: {
        after: endCursor,
      },
      // @ts-ignore just for testing
      updateQuery: (
        previousResult: T,
        { fetchMoreResult }: { fetchMoreResult: T | undefined },
      ) => {
        if (typeof fetchMoreResult !== 'undefined') {
          // @ts-expect-error since it is not well typed yet
          const newEdges = fetchMoreResult[key].edges
          // @ts-expect-error since it is not well typed yet
          const pageInfo = fetchMoreResult[key].pageInfo
          // @ts-expect-error since it is not well typed yet
          const totalCount = fetchMoreResult[key].totalCount

          return newEdges.length
            ? // @ts-expect-error since it is not well typed yet
              ({
                [key]: {
                  // @ts-expect-error since it is not well typed yet
                  __typename: previousResult[key].__typename,
                  // @ts-expect-error since it is not well typed yet
                  edges: [...previousResult[key].edges, ...newEdges],
                  pageInfo,
                  totalCount,
                },
              } as T)
            : previousResult
        }
        return previousResult
      },
    })
  }, [data, loading, fetchMore, key])

  return { data, error, loading, fetchMore: loadMore }
}
