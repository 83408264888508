import React from 'react'
import classNames from 'classnames'

const styles = [
  'bg-blue-500',
  'group',
  'relative',
  'w-full',
  'flex',
  'justify-center',
  'py-2',
  'px-4',
  'border',
  'border-transparent',
  'text-sm',
  'leading-5',
  'font-medium',
  'rounded-md',
  'text-white',
  'bg-purple-600',
  'hover:bg-purple-500',
  'focus:outline-none',
  'focus:border-purple-700',
  'focus:shadow-outline-purple',
  'active:bg-purple-700',
  'transition',
  'duration-150',
  'ease-in-out',
]

export default function Button(props: ButtonProps) {
  const { type, children, disabled = false, onClick, className } = props

  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames(
        styles,
        {
          'cursor-not-allowed': disabled,
          'opacity-50': disabled,
        },
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset'
  children: any
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}
