import React from 'react'
import useIntercom from '../../../core/hooks/useIntercom'

export default function NoOrders() {
  const intercom = useIntercom()

  return (
    <div>
      <div className="mt-5 bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Vous n'avez pas encore reçu de commandes
          </h3>
          <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
            <p>
              Commencez dès à présent à partager votre catalogue par mail ou sur vos réseaux
              sociaux pour apporter de la visibilité à votre plateforme de
              <i>click-and-collect</i>.
            </p>
          </div>
          <div className="mt-4 text-sm leading-5">
            <span
              className="font-medium text-purple-600 hover:text-purple-500 transition ease-in-out duration-150 cursor-pointer"
              onClick={intercom.show}
            >
              Prendre contact avec l'équipe Wino pour demander conseil →
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
