import React from 'react'
import classNames from 'classnames'
import NumberInput from '../inputs/NumberInput'

export default function NumberField(props: NumberFieldProps) {
  const {
    label,
    required,
    error,
    register,
    name,
    min,
    max,
    step,
    placeholder,
    unit,
    disabled,
    readOnly,
  } = props

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">
        {label} {required && '*'}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <NumberInput
          disabled={disabled}
          readOnly={readOnly}
          register={register}
          name={name}
          min={min}
          max={max}
          step={step}
          placeholder={placeholder}
          className={classNames(
            'transition duration-150 ease-in-out',
            !!error && 'border-red-600 focus:border-red-300',
            (disabled || readOnly) && 'opacity-50 cursor-not-allowed',
          )}
        />
        {typeof unit === 'string' && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm sm:leading-5">{unit}</span>
          </div>
        )}
      </div>
      {!!error && (
        <p className="mt-1 text-sm text-red-600" id={`${name}-error`}>
          {error.message}
        </p>
      )}
    </>
  )
}

interface NumberFieldProps {
  register: React.Ref<HTMLInputElement>
  label: string
  required?: boolean
  name: string
  type?: string
  placeholder?: string
  unit?: string
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  readOnly?: boolean
  error?: { message?: string }
}
