import React from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import SideBarShop from './SideBarShop'

export default function DesktopSideBar(props: DesktopSideBarProps) {
  const history = useHistory()
  const { menu } = props

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4 pb-4">
              <img
                className="hidden lg:block w-20"
                src={require('./logo.png').default}
                alt="Potico Office Logo"
              />
            </div>
            <nav className="mt-1 flex-1 px-2 bg-white space-y-1" role="menu">
              {menu.map((item) => (
                <div
                  key={item.title}
                  role="menuitem"
                  onClick={() => history.push(item.to)}
                  className={classnames(
                    'group flex items-center px-2 py-3 text-sm leading-5 font-medium text-gray-900 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150 cursor-pointer',
                    history.location.pathname.startsWith(item.to) && 'bg-gray-100',
                  )}
                >
                  {item.icon}
                  {item.title}
                </div>
              ))}
            </nav>
          </div>
          <SideBarShop />
        </div>
      </div>
    </div>
  )
}

interface DesktopSideBarProps {
  menu: {
    to: string
    icon: JSX.Element
    title: string
  }[]
}
