import React from 'react'
import classNames from 'classnames'

const styles = ['form-input', 'block', 'w-full', 'pr-20', 'sm:text-sm', 'sm:leading-5']

export default function NumberInput(props: NumberInputProps) {
  const {
    name,
    min,
    max,
    step,
    placeholder,
    className,
    register,
    disabled,
    readOnly,
  } = props

  return (
    <input
      disabled={disabled}
      readOnly={readOnly}
      ref={register}
      id={name}
      name={name}
      type="number"
      min={min}
      max={max}
      step={step}
      className={classNames([...styles], className)}
      placeholder={placeholder}
    />
  )
}

interface NumberInputProps {
  register: React.Ref<HTMLInputElement>
  placeholder?: string
  name: string
  className?: string
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  readOnly?: boolean
}
