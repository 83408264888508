import React, { useMemo, useState } from 'react'
import { createEditor, Node } from 'slate'
import { withReact } from 'slate-react'
import { ShopDetails } from '../../../core/models/shop/__generated__/ShopDetails'
import RichTextExample from '../../components/editors/RichTextEditor'
import { withHistory } from 'slate-history'

export default function TermsOfSaleForm(props: TermsOfSaleFormProps) {
  const { shop, onUpdateTermsOfSale } = props
  const initialValue = JSON.parse(
    shop.termsOfSale ??
      JSON.stringify([
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ]),
  )
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  const [value, setValue] = useState<Node[]>(initialValue)

  function onResetHandler() {
    setValue(initialValue)
  }

  function onSubmitHandler() {
    onUpdateTermsOfSale(JSON.stringify(value))
  }

  return (
    <div className="shadow sm:rounded-md sm:overflow-hidden">
      <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Conditions de vente
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Complétez vos conditions générales de vente avec attention. Nous vous
            recommandons d'être conseillé par un expert juridique. Nous mettons également à
            disposition{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="http://help.wino.fr/fr/articles/4690138-modele-de-conditions-generales-de-vente-du-commercant"
              className="underline hover:no-underline"
            >
              un modèle de conditions de vente
            </a>
          </p>
        </div>
      </div>
      <div className="bg-white py-4 px-4 space-y-6 sm:p-6">
        <RichTextExample
          editor={editor}
          value={value}
          onChange={(value) => setValue(value)}
          placeholder="Compléter vos conditions ici..."
        />
      </div>
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:flex sm:items-center sm:justify-end">
        <div className="flex space-x-3">
          <span className="shadow-sm rounded-md">
            <button
              onClick={onResetHandler}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
            >
              Annuler
            </button>
          </span>
          <button
            onClick={onSubmitHandler}
            className="bg-purple-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  )
}

interface TermsOfSaleFormProps {
  shop: ShopDetails
  onUpdateTermsOfSale: (data: string) => void
}
