import React, { useState } from 'react'
import classNames from 'classnames'

export default function SettingsLayout<T, K extends keyof T, S>(
  props: SettingsLayoutProps<T, K, S>,
) {
  const { pages, initialPage, menu, pageProps } = props
  const [currentPage, setCurrentPage] = useState<K>(initialPage)

  const Component = pages[currentPage].component

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <nav className="space-y-1">
          {menu.map((item) => {
            const page = pages[item]
            return (
              <button
                key={page.title}
                onClick={() => setCurrentPage(item)}
                className={classNames(
                  'w-full group bg-gray-50 rounded-md px-3 py-2 flex items-center text-sm font-medium focus:outline-none',
                  currentPage === item &&
                    'text-purple-700 hover:text-purple-700 hover:bg-white',
                  currentPage !== item &&
                    'text-gray-500 hover:text-gray-900 focus:bg-gray-200',
                )}
                aria-current="page"
              >
                {page.icon}
                <span className="truncate">{page.title}</span>
              </button>
            )
          })}
        </nav>
      </aside>
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <Component {...pageProps} />
      </div>
    </div>
  )
}

interface SettingsLayoutProps<T, K extends keyof T, S> {
  menu: K[]
  initialPage: K
  pages: Record<
    K,
    {
      component: (props: S) => JSX.Element
      icon: JSX.Element
      title: string
    }
  >
  pageProps: S
}
