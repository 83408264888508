import { format } from 'date-fns'
import frLocale from 'date-fns/locale/fr'
import { DiscountUnit } from '../__generated__/globalTypes'
import { DiscountDetails } from './__generated__/DiscountDetails'

export const discountUnits = [
  { value: DiscountUnit.EURO, label: 'Euro' },
  { value: DiscountUnit.PERCENTAGE, label: 'Pourcentage' },
]

export const activeStates = [
  { value: true, label: 'Actif' },
  { value: false, label: 'Inactif' },
]

export function renderDiscountPeriod(discount: DiscountDetails) {
  const startDate = formatDate(discount.validFrom)

  if (discount.validUntil) {
    const endDate = formatDate(discount.validUntil)
    return `Du ${startDate} au ${endDate}`
  } else {
    return `A partit du ${startDate}`
  }
}

function formatDate(date: string) {
  return format(new Date(date), 'dd.MM.yyyy', {
    locale: frLocale,
  })
}

export function computeBorderDate(date: Date, numberOfDayToAdd: number) {
  const dateUsed = new Date(date.valueOf())
  dateUsed.setDate(dateUsed.getDate() + numberOfDayToAdd)

  return dateUsed
}
