import * as Sentry from '@sentry/react'
import { SentryLink } from 'apollo-link-sentry'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import { API_BASE_URI, APP_AUTH_TOKEN, PRODUCTION } from '../constants'

const authMiddleware = setContext((_, { headers }) => {
  const token = localStorage.getItem(APP_AUTH_TOKEN)
  return { headers: { ...headers, authorization: token ? `Bearer ${token}` : '' } }
})

const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map((graphQLError) => {
      if (!PRODUCTION) {
        const { message, locations, path } = graphQLError
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        )
      }
      if (graphQLError.message === 'Not Authenticated') {
        localStorage.removeItem(APP_AUTH_TOKEN)
        // @ts-expect-error since it is is not typed
        window._history.push('/sign-in')
      } else if (PRODUCTION) {
        Sentry.captureException(graphQLError)
      }
      return null
    })
  }

  if (networkError) {
    if (PRODUCTION) {
      Sentry.captureException(networkError)
    } else {
      console.error(`[Network error]: ${networkError}`)
    }
  }
})

const cache = new InMemoryCache()

const uploadLink = createUploadLink({
  uri: `${API_BASE_URI}/graphql`,
})

const sentryLink = new SentryLink({
  setTransaction: true,
  setFingerprint: true,
  breadcrumb: {
    enable: true,
    includeQuery: true,
    includeCache: false,
    includeVariables: true,
    includeResponse: true,
    includeError: true,
  },
})

export const client = new ApolloClient({
  cache: cache,
  link: authMiddleware.concat(sentryLink).concat(errorMiddleware).concat(uploadLink),
})
