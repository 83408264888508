import React, { useState } from 'react'
import classNames from 'classnames'
import { ShopDetails } from '../../../core/models/shop/__generated__/ShopDetails'
import DeliveryChangeStateModal from './DeliveryChangeStateModal'
import { useAlerts } from '../../../core/contexts/alerts'
import DeliveryInformationForm from './DeliveryInformationForm'

function canActivateShopDelivery(shop: ShopDetails) {
  const errors = []

  if (
    !shop.homeDelivery ||
    !shop.homeDelivery.minDaysDelay ||
    !shop.homeDelivery.maxDaysDelay ||
    !shop.homeDelivery.price
  ) {
    errors.push('Certaines des informations de livraison sont manquantes')
  }

  return { errors }
}

export default function DeliverySettingsForm(props: DeliverySettingsFormProps) {
  const {
    shop,
    onActivateShopHomeDelivery,
    onDeactivateShopHomeDelivery,
    onUpdateShopHomeDelivery,
  } = props
  const { pushErrorAlert } = useAlerts()
  const [open, setOpen] = useState(false)

  const active = shop.homeDelivery?.active === true

  function onValidateHandler() {
    if (active) {
      onDeactivateShopHomeDelivery()
    } else {
      const { errors } = canActivateShopDelivery(shop)
      if (errors.length > 0) {
        pushErrorAlert(
          <>
            <h3 className="text-sm font-medium text-red-800">
              La boutique n'est pas encore prête à faire des livraison, veuillez corriger
              les informations suivantes
            </h3>
            <ul className="list-disc pl-5 space-y-1">
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </>,
        )
      } else {
        onActivateShopHomeDelivery()
      }
    }
    setOpen(false)
  }

  return (
    <>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Option de livraison
          </h3>
          <div className="mt-2 max-w-xxl text-sm text-gray-500 ">
            <p>
              {active
                ? 'Les internautes peuvent commander des produits en click and collect ou être livrer à domicile (France uniquement)'
                : 'Activer la livraison pour permettre à des clients de toute la France de commander facilement sur votre boutique en ligne.'}
            </p>
          </div>
          {active && (
            <p className="mt-3 flex items-center text-sm text-gray-500">
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Livraison activée
            </p>
          )}
          <div className="mt-4">
            <button
              onClick={() => setOpen(true)}
              type="button"
              className={classNames(
                'inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm cursor-pointer',
                active && 'text-red-500 bg-red-300  hover:bg-red-200 focus:ring-red-500',
                !active &&
                  'text-green-700 bg-green-300  hover:bg-green-200 focus:ring-green-500',
              )}
            >
              {active ? 'Désactiver la livraison' : 'Activer la livraison'}
            </button>
          </div>
        </div>
      </div>
      <DeliveryInformationForm
        shop={shop}
        onUpdateShopHomeDelivery={onUpdateShopHomeDelivery}
      />
      <DeliveryChangeStateModal
        open={open}
        active={active}
        onCancel={() => setOpen(false)}
        onValidate={onValidateHandler}
      />
    </>
  )
}

interface DeliverySettingsFormProps {
  shop: ShopDetails
  onActivateShopHomeDelivery: () => void
  onDeactivateShopHomeDelivery: () => void
  onUpdateShopHomeDelivery: (data: {
    price: number
    minDaysDelay: number
    maxDaysDelay: number
  }) => void
}
