import { OrderStatus, PermitedOrderStatus } from '../__generated__/globalTypes'

export const OrderStatusColor = {
  [OrderStatus.PAYED]: {
    text: '#0B5135',
    background: '#BEF1DC',
  },
  [OrderStatus.VALIDATED]: {
    text: '#0B5135',
    background: '#BEF1DC',
  },
  [OrderStatus.PREPARED]: {
    text: '#0B5135',
    background: '#BEF1DC',
  },
  [OrderStatus.DELIVERED]: {
    text: '#0B5135',
    background: '#BEF1DC',
  },
  [OrderStatus.CANCELED]: {
    text: '#C81C51',
    background: '#FFE0E9',
  },
  [OrderStatus.REFUNDED]: {
    text: '#0B5135',
    background: '#BEF1DC',
  },
}

export const OrderStatusTranslation = {
  [OrderStatus.PAYED]: 'Payée',
  [OrderStatus.VALIDATED]: 'Confirmée',
  [OrderStatus.PREPARED]: 'Prête',
  [OrderStatus.DELIVERED]: 'Livrée',
  [OrderStatus.CANCELED]: 'Annulée',
  [OrderStatus.REFUNDED]: 'Remboursée',
}

export function generateAllowedStates(status: OrderStatus) {
  if (status === OrderStatus.DELIVERED || status === OrderStatus.REFUNDED) {
    return []
  }
  if (status === OrderStatus.CANCELED) {
    return [PermitedOrderStatus.REFUNDED]
  }
  if (status === OrderStatus.PREPARED) {
    return [PermitedOrderStatus.DELIVERED, PermitedOrderStatus.CANCELED]
  }
  if (status === OrderStatus.VALIDATED) {
    return [PermitedOrderStatus.PREPARED, PermitedOrderStatus.CANCELED]
  }
  if (status === OrderStatus.PAYED) {
    return [PermitedOrderStatus.VALIDATED, PermitedOrderStatus.CANCELED]
  }
  return []
}
