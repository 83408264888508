import { useEffect, MutableRefObject, DependencyList } from 'react'

export function useClickOutside<T extends HTMLElement | null>(
  ref: MutableRefObject<T>,
  callback: Function,
  deps?: DependencyList | undefined,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // @ts-expect-error since EventTarget is not Node
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback, deps])
}
