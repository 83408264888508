import { MutationHookOptions, useQuery, useMutation } from '@apollo/client'
import { GET_ORDERS, GET_ORDER } from './queries'
import { GetOrders, GetOrdersVariables } from './__generated__/GetOrders'
import { GetOrder, GetOrderVariables } from './__generated__/GetOrder'
import { useShopsContext } from '../../contexts/shops'
import usePagination from '../../hooks/usePagination'
import {
  ChangeOrderStatus,
  ChangeOrderStatusVariables,
} from './__generated__/ChangeOrderStatus'
import { CHANGE_ORDER_STATUS } from './mutations'
import { useCallback } from 'react'

export function useOrders(variables: Omit<GetOrdersVariables, 'shopId'>) {
  const { currentShop } = useShopsContext()
  const { loading, data, error, fetchMore } = usePagination<GetOrders, GetOrdersVariables>(
    GET_ORDERS,
    'orders',
    {
      variables: {
        shopId: currentShop,
        statusIn: variables.name ? [] : variables.statusIn,
        name: variables.name ? variables.name : undefined,
      },
      fetchPolicy: 'cache-and-network',
    },
  )

  return { loading, error, orders: data?.orders, fetchMore }
}

export function useOrder(variables: GetOrderVariables) {
  const { loading, data, error } = useQuery<GetOrder, GetOrderVariables>(GET_ORDER, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

  return { loading, error, order: data?.order }
}

export function useChangeOrderStatus(
  options?: MutationHookOptions<ChangeOrderStatus, ChangeOrderStatusVariables>,
) {
  const [mutation, { data, loading }] = useMutation<
    ChangeOrderStatus,
    ChangeOrderStatusVariables
  >(CHANGE_ORDER_STATUS, options)

  const changeOrderStatus = useCallback(
    async (variables: ChangeOrderStatusVariables) => {
      await mutation({ variables })
    },
    [mutation],
  )

  return { changeOrderStatus, order: data?.changeOrderStatus, loading }
}
