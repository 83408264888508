import React, { useState } from 'react'
import classNames from 'classnames'

const styles = [
  'form-input',
  'appearance-none',
  'rounded-none',
  'relative',
  'block w-full',
  'border',
  'border-gray-300',
  'placeholder-gray-500',
  'text-gray-900',
  'rounded-md',
  'focus:outline-none',
  'focus:shadow-outline-blue',
  'focus:border-blue-300',
  'focus:z-10',
  'sm:text-sm',
  'sm:leading-5',
]

export default function StringInput(props: StringInputProps) {
  const {
    ariaLabel,
    name,
    type,
    placeholder,
    className,
    register,
    disabled,
    readOnly,
    onSubmit = () => {},
  } = props

  const [value, setValue] = useState(props.value ?? '')

  return (
    <input
      ref={register}
      aria-label={ariaLabel}
      id={name}
      name={name}
      type={type}
      disabled={disabled}
      readOnly={readOnly}
      {...(!register
        ? {
            value: value,
            onBlur: () => onSubmit(value),
            onChange: (e) => setValue(e.target.value),
          }
        : {})}
      className={classNames([...styles], className)}
      placeholder={placeholder}
    />
  )
}

interface StringInputProps {
  register?: React.Ref<HTMLInputElement>
  placeholder?: string
  type?: string
  name: string
  ariaLabel?: string
  className?: string
  disabled?: boolean
  readOnly?: boolean
  onSubmit?: (value: string) => void
  value?: string
}
