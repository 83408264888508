import React from 'react'
import classNames from 'classnames'
import StringInput from '../inputs/StringInput'

export default function StringField(props: StringFieldProps) {
  const {
    label,
    required,
    error,
    register,
    name,
    type,
    placeholder,
    information,
    ariaLabel,
    disabled,
    readOnly,
    onSubmit,
    value,
  } = props

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">
        {label} {required && '*'}
      </label>
      <div className="mt-1 rounded-md shadow-sm">
        <StringInput
          register={register}
          ariaLabel={ariaLabel}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          value={value}
          onSubmit={onSubmit}
          className={classNames(
            'transition duration-150 ease-in-out',
            !!error && 'border-red-600 focus:border-red-300',
            (disabled || readOnly) && 'opacity-50 cursor-not-allowed',
          )}
        />
      </div>
      <p className="mt-1 text-sm text-gray-500">{information}</p>
      {!!error && (
        <p className="mt-1 text-sm text-red-600" id={`${name}-error`}>
          {error.message}
        </p>
      )}
    </>
  )
}

interface StringFieldProps {
  register?: React.Ref<HTMLInputElement>
  label: string
  required?: boolean
  name: string
  type?: string
  placeholder?: string
  information?: string
  ariaLabel?: string
  disabled?: boolean
  readOnly?: boolean
  error?: { message?: string }
  onSubmit?: (value: string) => void
  value?: string
}
