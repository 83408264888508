import React, { useState, useEffect, useContext, createContext } from 'react'
import axios from 'axios'
import { API_BASE_URI, APP_AUTH_TOKEN, CURRENT_SHOP_ID } from '../constants'
import useIntercom from '../hooks/useIntercom'
import { useAccount } from '../models/account/hooks'

type ShopContext = {
  currentShop: string
  availableShops: string[]
  switchTo: (shopId: string) => void
  createPaymentAccount: (
    shopId: string,
  ) => Promise<[{ url?: string }, { error?: { message: string }; status?: number }]>
}

const ShopsStore = createContext<ShopContext>({
  currentShop: '',
  availableShops: [],
  switchTo: () => {},
  createPaymentAccount: async (_shopId: string) => [{}, {}],
})

const { Provider } = ShopsStore

export function ShopsProvider(props: ShopsProviderProps) {
  const { availableShops, children } = props
  const shops = useProvideShops(availableShops)
  return <Provider value={shops}>{children}</Provider>
}

interface ShopsProviderProps {
  availableShops: string[]
  children: React.ReactNode
}

export function useShopsContext() {
  return useContext(ShopsStore)
}

function initCurrentShop(shops: string[]) {
  const localCurrentShop = localStorage.getItem(CURRENT_SHOP_ID) ?? ''
  if (shops.includes(localCurrentShop)) {
    return localCurrentShop
  } else {
    localStorage.setItem(CURRENT_SHOP_ID, shops[0])
    return shops[0]
  }
}

function useProvideShops(shops: string[]) {
  const intercom = useIntercom()
  const { account } = useAccount({})
  const [currentShop, setCurrentShop] = useState<string>(initCurrentShop(shops))
  const [availableShops, setAvailableShops] = useState<string[]>(shops)

  const switchTo = (shopId: string) => {
    if (availableShops.includes(shopId)) {
      localStorage.setItem(CURRENT_SHOP_ID, shopId)
      setCurrentShop(shopId)
    }
  }

  const createPaymentAccount = async (
    shopId: string,
  ): Promise<[{ url?: string }, { error?: { message: string }; status?: number }]> => {
    try {
      const token = localStorage.getItem(APP_AUTH_TOKEN)
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }
      const response = await axios.get(
        `${API_BASE_URI}/payment/account/create?shopId=${shopId}`,
        config,
      )
      return [response.data, {}]
    } catch (error) {
      return [{}, { error: error?.response?.data, status: error?.response?.status }]
    }
  }

  useEffect(() => {
    setAvailableShops(shops)
  }, [shops])

  useEffect(() => {
    if (account) {
      const shop = account.shops.edges.find((edge) => edge.node.id === currentShop)
      if (shop?.node.originId) {
        intercom.boot(account.email, shop?.node.originId)
      }
    }
  }, [account, currentShop, intercom])

  return {
    currentShop,
    availableShops,
    switchTo,
    createPaymentAccount,
  }
}
