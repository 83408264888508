import { Transition } from '@tailwindui/react'
import React, { useState, useRef } from 'react'
import classNames from 'classnames'

import { useClickOutside } from '../../../core/hooks/useClickOutside'

export function SelectMenu<T extends string>(props: SelectMenuProps<T>) {
  const {
    label,
    selectedItems,
    items,
    onSelect,
    renderItem,
    onClose,
    placeholder,
    isMultiselect = false,
    isDisabled = false,
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  useClickOutside(ref, onOutsideClickHandler, [selectedItems])

  function onOutsideClickHandler() {
    onClose()
    setOpen(false)
  }

  function renderSelectedItems(items: T[]) {
    return items.map((item, index) => renderItem(item, index))
  }

  function renderItemsList(items: T[], selectedItems: T[]) {
    return items.map((item, index) => (
      <li
        key={`${index}-${item}`}
        onClick={() => onSelect(item)}
        className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
      >
        <div className="flex items-center">{renderItem(item, index)}</div>
        {selectedItems.includes(item) && (
          <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4')}>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        )}
      </li>
    ))
  }

  return (
    <div ref={ref} className={!isMultiselect ? 'w-80' : ''}>
      {label && (
        <label id="listbox-label" className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <button
        type="button"
        onClick={() => {
          if (!isDisabled) {
            if (open) {
              onClose()
            }
            setOpen(!open)
          }
        }}
        className={`relative mt-1 h-max w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none sm:text-sm ${
          !isMultiselect
            ? 'focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
            : 'focus:shadow-outline-blue focus:border-blue-300'
        }`}
      >
        {selectedItems.length === 0 && (
          <span className="m-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-400 text-white">
            {placeholder}
          </span>
        )}
        {renderSelectedItems(selectedItems)}
        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg
            className="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </button>
      <div className="mt-1 relative">
        <Transition show={open} leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="absolute mt-1 z-10 w-full rounded-md bg-white shadow-lg">
            <ul
              tabIndex={1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-item-3"
              className="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            >
              {renderItemsList(items, selectedItems)}
            </ul>
          </div>
        </Transition>
      </div>
    </div>
  )
}

interface SelectMenuProps<T extends string> {
  label?: string
  items: T[]
  selectedItems: T[]
  onSelect: (item: T) => void
  onClose: () => void
  renderItem: (item: T, key: string | number) => React.ReactNode
  placeholder: string
  isMultiselect?: boolean
  isDisabled?: boolean
}
