import { gql } from '@apollo/client'

export const DISCOUNT_DETAILS = gql`
  fragment DiscountDetails on Discount {
    id
    unit
    couponCode
    value
    minimumOrderValue
    maximumDiscountAmount
    validFrom
    validUntil
    active
    usageCounter
    createdAt
    updatedAt
    archivedAt
  }
`
