import React, { useState } from 'react'

import { SelectMenu } from './SelectMenu'

export default function MultiSelectMenu<T extends string>(props: MultiSelectMenuProps<T>) {
  const {
    label,
    initialSelectedItems = [],
    items,
    onChange = () => {},
    renderItem,
    isDisabled = false,
    placeholder,
  } = props
  const [selectedItems, setSelectedItems] = useState<T[]>(initialSelectedItems)
  return (
    <SelectMenu
      label={label}
      items={items}
      selectedItems={selectedItems}
      onClose={() => onChange(selectedItems)}
      onSelect={(item) => {
        const selected = selectedItems.includes(item)
        if (selected) {
          setSelectedItems(selectedItems.filter((x) => x !== item))
        } else {
          setSelectedItems([...selectedItems, item])
        }
      }}
      renderItem={renderItem}
      placeholder={placeholder}
      isMultiselect
      isDisabled={isDisabled}
    />
  )
}

interface MultiSelectMenuProps<T extends string> {
  label?: string
  items: T[]
  initialSelectedItems?: T[]
  onChange?: (items: T[]) => void
  renderItem: (item: T, key: string | number) => React.ReactNode
  isDisabled?: boolean
  placeholder: string
}
