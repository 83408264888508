import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import useQueryParams from '../../core/hooks/useQueryParams'
import { useDiscounts } from '../../core/models/discount/hooks'
import TableNavigation from '../../lib/components/tables/TableNavigation'
import Container from '../../lib/components/layout/Container'
import DiscountRow from '../../lib/containers/discounts/DiscountRow'
import Loading from '../../lib/components/layout/Loading'
import NoDiscounts from '../../lib/containers/discounts/NoDiscounts'

const FETCHING_LIMIT = 10

export default function DiscountsPage() {
  const history = useHistory()
  const { after, before } = useQueryParams(['before', 'after'])
  const { discounts } = useDiscounts({
    ...(after
      ? { first: FETCHING_LIMIT, after }
      : before
      ? { before, last: FETCHING_LIMIT }
      : { first: FETCHING_LIMIT }),
  })

  const onNextHandler = useCallback(() => {
    history.push(`/discounts?after=${discounts?.pageInfo.endCursor}`)
  }, [history, discounts])

  const onPreviousHandler = useCallback(() => {
    history.push(`/discounts?before=${discounts?.pageInfo.startCursor}`)
  }, [history, discounts])

  if (!discounts) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  const { edges } = discounts

  if (edges.length === 0) {
    return (
      <Container>
        <NoDiscounts />
      </Container>
    )
  }

  return (
    <Container>
      <div className="pt-2 pb-6 md:py-6">
        <h1 className="text-2xl font-semibold text-gray-900">Codes promotionnels</h1>
        <div className="pb-5 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
          <p className="mt-1 max-w-xl text-sm leading-5 text-gray-500">
            Augmentez vos ventes et récompensez vos clients avec des codes de réduction
            <br />
            crées dans potico.
          </p>
          <p></p>
          <div className="flex space-x-3">
            <span className="shadow-sm rounded-md">
              <button
                onClick={() => history.push('/discounts/create')}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-purple-700 transition duration-150 ease-in-out"
              >
                Nouveau
              </button>
            </span>
          </div>
        </div>
        <div className="py-4">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          CODE
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          PÉRIODE
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          MONTANT PANIER MINIMUM
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          USAGE
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          STATUT
                        </th>
                        <th className="px-4 py-3 bg-gray-50" />
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {edges.map((discount) => (
                        <DiscountRow key={discount.node.id} discount={discount.node} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableNavigation
          hasNextPage={discounts.pageInfo.hasNextPage}
          hasPreviousPage={discounts.pageInfo.hasPreviousPage}
          onNext={onNextHandler}
          onPrevious={onPreviousHandler}
        />
      </div>
    </Container>
  )
}
