import React from 'react'
import classNames from 'classnames'

const styles = [
  'form-select',
  'block',
  'w-full',
  'transition',
  'duration-150',
  'ease-in-out',
  'sm:text-sm',
  'sm:leading-5',
]

export default function SelectInput<T>(props: StringInputProps<T>) {
  const { name, className, register, options, disabled, readOnly, value } = props

  return (
    <select
      ref={register}
      id={name}
      name={name}
      className={classNames([...styles], className)}
      disabled={disabled}
    >
      {options
        .filter((x) => {
          if (disabled || readOnly) return x.value === value
          return true
        })
        .map((option) => (
          <option
            key={option.label}
            value={String(option.value)}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
    </select>
  )
}

interface StringInputProps<T> {
  register: React.Ref<HTMLSelectElement>
  name: string
  value: T
  options: { label: string; value: T; disabled?: boolean }[]
  className?: string
  disabled?: boolean
  readOnly?: boolean
}
