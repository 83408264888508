import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { ShopDetails } from '../../../core/models/shop/__generated__/ShopDetails'
import NumberField from '../../components/fields/NumberField'

export type FormSchema = {
  price: string
  minDaysDelay: string
  maxDaysDelay: string
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FormSchema = Yup.object().shape<FormSchema>({
  price: Yup.string()
    .required('Ce champ est requis')
    .test(
      'valid-price',
      'Prix non valide',
      (value) => !isNaN(Number(value)) && Number(value) > 0,
    ),
  minDaysDelay: Yup.string()
    .required('Ce champ est requis')
    .test(
      'valid-minDaysDelay',
      'Nombre de jours minimum non valide',
      (value) => !isNaN(Number(value)) && Number(value) > 0,
    ),
  maxDaysDelay: Yup.string()
    .required('Ce champ est requis')
    .test(
      'valid-maxDaysDelay',
      'Nombre de jours maximum non valide',
      (value) => !isNaN(Number(value)) && Number(value) > 0,
    ),
})

export default function DeliveryInformationForm(props: DeliveryInformationFormProps) {
  const { shop, onUpdateShopHomeDelivery } = props
  const initialValues = {
    price: shop.homeDelivery?.price ? String(shop.homeDelivery?.price) : '',
    minDaysDelay: shop.homeDelivery?.minDaysDelay
      ? String(shop.homeDelivery?.minDaysDelay)
      : '',
    maxDaysDelay: shop.homeDelivery?.maxDaysDelay
      ? String(shop.homeDelivery?.maxDaysDelay)
      : '',
  }

  const { errors, register, reset, handleSubmit } = useForm<FormSchema>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: initialValues,
  })

  function onSubmitHandler(data: FormSchema) {
    onUpdateShopHomeDelivery({
      price: Number(data.price),
      minDaysDelay: Number(data.minDaysDelay),
      maxDaysDelay: Number(data.maxDaysDelay),
    })
  }

  function onResetHandler(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    reset(initialValues)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900 min-w-max">
                Paramétrer la livraison
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500 ">
                <p>Indiquer un montant de frais fixe et vos délais estimés de livraison</p>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-6">
              {/* Price */}
              <div className="col-span-6 sm:col-span-3">
                <NumberField
                  register={register}
                  required={true}
                  label="Frais de livraison TTC"
                  name="price"
                  min={0.0}
                  max={10000.0}
                  step={0.01}
                  placeholder="0.0"
                  unit="EUR (€)"
                  error={errors.price}
                />
              </div>
              <div className="sm:col-span-3" />
              {/* minDaysDelay */}
              <div className="col-span-6 sm:col-span-3">
                <NumberField
                  register={register}
                  required={true}
                  label="Délai minimum de livraison en jours ouvrés"
                  name="minDaysDelay"
                  min={0}
                  max={30}
                  step={1}
                  placeholder="1"
                  unit="Jour(s)"
                  error={errors.minDaysDelay}
                />
              </div>
              <div className="sm:col-span-3" />
              {/* maxDaysDelay */}
              <div className="col-span-6 sm:col-span-3">
                <NumberField
                  register={register}
                  required={true}
                  label="Délai maximum de livraison en jours ouvrés"
                  name="maxDaysDelay"
                  min={0}
                  max={30}
                  step={1}
                  placeholder="1"
                  unit="Jour(s)"
                  error={errors.maxDaysDelay}
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:flex sm:items-center sm:justify-end">
            <div className="flex space-x-3">
              <span className="shadow-sm rounded-md">
                <button
                  onClick={onResetHandler}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                >
                  Annuler
                </button>
              </span>
              <button
                type="submit"
                className="bg-purple-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

interface DeliveryInformationFormProps {
  shop: ShopDetails
  onUpdateShopHomeDelivery: (data: {
    price: number
    minDaysDelay: number
    maxDaysDelay: number
  }) => void
}
