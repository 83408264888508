import React from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import frLocale from 'date-fns/locale/fr'

import { OrderDetails } from '../../../core/models/order/__generated__/OrderDetails'
import { OrderStatus } from '../../../core/models/__generated__/globalTypes'
import { OrderStatusTranslation, OrderStatusColor } from '../../../core/models/order/utils'

export function renderOrderStatus(status: OrderStatus) {
  let nodes = [
    {
      text: OrderStatusTranslation[status],
      textColor: OrderStatusColor[status].text,
      backgroundColor: OrderStatusColor[status].background,
    },
  ]

  if (status === OrderStatus.PAYED) {
    nodes = [
      {
        text: `À confirmer`,
        textColor: '#907B1E',
        backgroundColor: '#FFF2BA',
      },
    ]
  }

  if (status === OrderStatus.VALIDATED) {
    nodes.push({
      text: `Non préparé`,
      textColor: '#907B1E',
      backgroundColor: '#FFF2BA',
    })
  }

  if (status === OrderStatus.PREPARED) {
    nodes.push({
      text: `Non livrée`,
      textColor: '#907B1E',
      backgroundColor: '#FFF2BA',
    })
  }

  if (status === OrderStatus.REFUNDED) {
    nodes = [
      {
        text: OrderStatusTranslation[OrderStatus.CANCELED],
        textColor: OrderStatusColor[OrderStatus.CANCELED].text,
        backgroundColor: OrderStatusColor[OrderStatus.CANCELED].background,
      },
    ]
  }

  return nodes.map((node, index) => (
    <span
      key={index}
      className="m-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
      style={{
        color: node.textColor,
        backgroundColor: node.backgroundColor,
      }}
    >
      {node.text}
    </span>
  ))
}

export function renderOrderPaymentStatus(status: OrderStatus) {
  let nodes = [
    {
      text: OrderStatusTranslation[OrderStatus.PAYED],
      textColor: OrderStatusColor[OrderStatus.PAYED].text,
      backgroundColor: OrderStatusColor[OrderStatus.PAYED].background,
    },
  ]

  if (status === OrderStatus.CANCELED) {
    nodes.push({
      text: 'Non remboursée',
      textColor: '#907B1E',
      backgroundColor: '#FFF2BA',
    })
  }

  if (status === OrderStatus.REFUNDED) {
    nodes = [
      {
        text: OrderStatusTranslation[OrderStatus.REFUNDED],
        textColor: OrderStatusColor[OrderStatus.REFUNDED].text,
        backgroundColor: OrderStatusColor[OrderStatus.REFUNDED].background,
      },
    ]
  }

  return nodes.map((node, index) => (
    <span
      key={index}
      className="m-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
      style={{
        color: node.textColor,
        backgroundColor: node.backgroundColor,
      }}
    >
      {node.text}
    </span>
  ))
}

export default function OrderRow(props: OrderRowProps) {
  const { order } = props
  const {
    id,
    name,
    createdAt,
    products,
    status,
    customer,
    totalAmountIncludingTaxes,
    totalAmountIncludingDiscount,
    discount,
  } = order
  const quantities = products.reduce((acc, product) => acc + product.quantity, 0)

  return (
    <tr>
      <td className="px-6 py-4 whitespace-no-wrap">
        <div className="text-sm leading-5 text-gray-500">N°{name}</div>
        <div className="text-sm text-cool-gray-900">
          {format(new Date(createdAt), 'dd/MM/yyyy à HH:mm', {
            locale: frLocale,
          })}
        </div>
      </td>
      <td className="px-2 py-3 whitespace-no-wrap">
        <div className="flex items-center">
          <div className="">
            <div className="text-sm leading-5 font-medium text-gray-900">
              {customer.name}
            </div>
            <div className="text-sm leading-5 text-gray-500">{customer.email}</div>
          </div>
        </div>
      </td>
      <td className="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        <span className="text-cool-gray-900 font-medium">
          {quantities} produit{quantities > 1 && 's'}
        </span>
      </td>
      <td className="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        <span className="text-cool-gray-900 font-medium">
          {discount?.value ? totalAmountIncludingDiscount : totalAmountIncludingTaxes}€{' '}
        </span>
        TTC
      </td>
      <td className="px-2 py-4 whitespace-no-wrap">{renderOrderStatus(status)}</td>
      <td className="px-2 py-4 whitespace-no-wrap">{renderOrderPaymentStatus(status)}</td>
      <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
        <Link to={`/orders/${id}`}>
          <span className="text-purple-600 hover:text-purple-900">→ Voir</span>
        </Link>
      </td>
    </tr>
  )
}

interface OrderRowProps {
  order: OrderDetails
}
