import React, { useState } from 'react'
import { ShopDetails } from '../../../core/models/shop/__generated__/ShopDetails'
import ImageField from '../../components/fields/ImageField'

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
const FILE_SIZE = 5242880 // 5MB

type ImageStateType = { uri?: string; file?: File; error?: { message: string } }

export default function ShopImagesForm(props: ShopImagesFormProps) {
  const { shop, onUpdateShopImages } = props
  const initialState = [...shop.images, {}, {}, {}].slice(0, 3)
  const [images, setImages] = useState<ImageStateType[]>(initialState)

  function onDropHandler(acceptedFiles: File[], index: number) {
    const file = acceptedFiles[0]
    if (file) {
      if (file.size > FILE_SIZE) {
        images[index] = { error: { message: 'Poids de fichier trop important (max 5MB)' } }
      } else if (!SUPPORTED_FORMATS.includes(file.type)) {
        images[index] = { error: { message: 'Format de fichier non supporté' } }
      } else {
        images[index] = { uri: URL.createObjectURL(file), file, error: undefined }
      }
      setImages([...images])
    }
  }

  function onResetHandler() {
    setImages(initialState)
  }

  function onSubmitHandler() {
    const data = images
      .map((image) => {
        if (image.file) {
          return { file: image.file }
        } else if (image.uri) {
          return { uri: image.uri }
        }
        return null
      })
      .filter((x) => !!x) as { uri?: string; file?: File }[]
    onUpdateShopImages(data)
  }

  function renderImageInput(image: ImageStateType, index: number) {
    return (
      <div key={index}>
        <ImageField
          name={`image-${index}`}
          image={image}
          onDrop={(acceptedFiles: File[]) => onDropHandler(acceptedFiles, index)}
          error={image.error}
        />
      </div>
    )
  }

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Images de la boutique
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500 ">
            <p>
              Trois images à choisir avec soin pour présenter votre magasin aux internautes
              <p>
                (ex : extérieur de la boutique, intérieur de la boutique, logo ou seconde
                image de l'intérieur)
              </p>
            </p>
          </div>
          <div className="mt-5 grid grid-cols-2 gap-4">
            {images.map((image, index) => renderImageInput(image, index))}
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:flex sm:items-center sm:justify-end">
          <div className="flex space-x-3">
            <span className="shadow-sm rounded-md">
              <button
                onClick={onResetHandler}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
              >
                Annuler
              </button>
            </span>
            <button
              onClick={onSubmitHandler}
              className="bg-purple-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

interface ShopImagesFormProps {
  shop: ShopDetails
  onUpdateShopImages: (data: { uri?: string; file?: File }[]) => void
}
