import React from 'react'

export default function Container(props: ContainerProps) {
  const { children } = props
  return <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-24">{children}</div>
}

interface ContainerProps {
  children: React.ReactChild | React.ReactChild[]
}
