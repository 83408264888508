import { gql } from '@apollo/client'
import { SHOP_DETAILS } from '../shop/fragments'

export const GET_ACCOUNT = gql`
  query GetAccount($first: Int, $last: Int, $before: String, $after: String) {
    account {
      email
      scope {
        role
        shopIds
      }
      shops(first: $first, last: $last, before: $before, after: $after) {
        edges {
          cursor
          node {
            ...ShopDetails
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
        totalCount
      }
    }
  }
  ${SHOP_DETAILS}
`
