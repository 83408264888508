import axios from 'axios'
import * as Sentry from '@sentry/react'

import { APP_AUTH_TOKEN, API_BASE_URI } from '../../constants'
import {
  ProductKind,
  ProductColor,
  ProductWineType,
  ProductWhiteWineType,
  ProductPicto,
} from '../__generated__/globalTypes'
import { Maybe } from '../../types'
import { ProductDetails } from './__generated__/ProductDetails'

export const activeStates = [
  { value: true, label: 'Publié' },
  { value: false, label: 'Non-publié' },
]

export const kinds = [
  { value: ProductKind.WINE, label: 'Vin' },
  { value: ProductKind.BEER, label: 'Bière' },
  { value: ProductKind.SPIRITUOUS, label: 'Spiritueux' },
  { value: ProductKind.SIMPLE, label: 'Épicerie et Cie' },
  { value: ProductKind.COMPOSED, label: 'Coffret' },
]

export const wineColors = [
  { value: ProductColor.WHITE, label: 'Blanc' },
  { value: ProductColor.RED, label: 'Rouge' },
  { value: ProductColor.ROSE, label: 'Rosé' },
]

export const beerColors = [
  { value: ProductColor.WHITE, label: 'Blanche' },
  { value: ProductColor.RED, label: 'Rouge' },
  { value: ProductColor.AMBER, label: 'Ambrée' },
  { value: ProductColor.BLOND, label: 'Blonde' },
  { value: ProductColor.DARK, label: 'Brune' },
  { value: ProductColor.BLACK, label: 'Noire' },
]

export const wineTypes = [
  { value: ProductWineType.STILL, label: 'Tranquille' },
  { value: ProductWineType.EFFERVESCENT, label: 'Effervescent' },
]

export const whiteWineTypes = [
  { value: ProductWhiteWineType.DRY, label: 'Sec' },
  { value: ProductWhiteWineType.SEMI_DRY, label: 'Demi-sec' },
  { value: ProductWhiteWineType.SOFT, label: 'Moelleux' },
  { value: ProductWhiteWineType.SWEET, label: 'Liquoreux' },
]

export const pictosList = [
  { value: ProductPicto.BIO, label: 'Bio', color: '#2CAA55' },
  { value: ProductPicto.REASONED, label: 'Raisonné', color: '#455FB2' },
  { value: ProductPicto.VEGAN, label: 'Vegan', color: '#535165' },
  { value: ProductPicto.NATURE, label: 'Nature', color: '#3FAFA3' },
  { value: ProductPicto.BIODYNAMICS, label: 'Biodynamie', color: '#066A4D' },
]

export const taxes = [
  { value: 20, label: '20%' },
  { value: 10, label: '10%' },
  { value: 5.5, label: '5.5%' },
]

export async function importProduct(variantId: string, shopId: string) {
  try {
    const token = localStorage.getItem(APP_AUTH_TOKEN)
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }
    const { data } = await axios.post<ImportedProduct>(
      `${API_BASE_URI}/import/product`,
      { shopId, variantId },
      config,
    )
    return { success: true, data }
  } catch (error) {
    if (error?.response?.status !== 404) {
      Sentry.captureMessage(`Error with variantId(${variantId}) shopId(${shopId})`)
      Sentry.captureException(error)
    }
    return { success: false, error: error?.response?.data }
  }
}

export function translateProductColor(kind: ProductKind, color: ProductColor | null) {
  if (kind === ProductKind.WINE) {
    return wineColors.find((x) => x.value === color)?.label
  } else if (kind === ProductKind.BEER) {
    return beerColors.find((x) => x.value === color)?.label
  }
  return
}

export function productCategory(product: ProductDetails) {
  switch (product.kind) {
    case ProductKind.BEER:
      return 'Bières'
    case ProductKind.SIMPLE:
      return 'Épicerie et Cie'
    case ProductKind.COMPOSED:
      return 'Coffret'
    case ProductKind.SPIRITUOUS:
      return 'Spiritueux'
    case ProductKind.WINE:
      if (product.wineType === ProductWineType.EFFERVESCENT) {
        return 'Bulles'
      }
      return `Vins ${translateProductColor(product.kind, product.color)}`
  }
}

export function productDescription(
  product: Pick<
    ProductDetails,
    | 'kind'
    | 'producer'
    | 'color'
    | 'country'
    | 'region'
    | 'designation'
    | 'beerType'
    | 'family'
  >,
) {
  const kind = product.kind
  let words: (string | undefined | null)[] = [product.producer]
  if (kind === ProductKind.WINE) {
    words = [...words, product.designation, product.region, product.country]
  } else if (kind === ProductKind.BEER) {
    words = [
      ...words,
      translateProductColor(kind, product.color),
      product.beerType,
      product.country,
    ]
  } else if (kind === ProductKind.SPIRITUOUS) {
    words = [...words, product.family, product.country]
  }
  return words.filter((x) => !!x).join(', ')
}

export type ImportedProduct = {
  variantId: string
  tax: Maybe<string>
  name: string
  variantName: string
  producer: string
  country: string
  region?: Maybe<string>
  family?: Maybe<string>
  beerType?: Maybe<string>
  wineType?: Maybe<string>
  pictos?: Maybe<ProductPicto[]>
  whiteWineType?: Maybe<string>
  description: string
  designation?: Maybe<string>
  crossedPrice?: Maybe<number>
  kind: string
  color: string
  stock: number
  tastingNote: Maybe<string>
  prices: {
    name: string
    value: number
    isDefault: Maybe<boolean>
  }[]
}
export function getPictoColorFromValue(picto: ProductPicto) {
  return pictosList.find((x) => x.value === picto)?.color
}

export function getPictoLabel(picto: ProductPicto) {
  return pictosList.find((x) => x.value === picto)?.label
}

export function getPictoColorFromLabel(picto: string) {
  return pictosList.find((x) => x.label === picto)?.color
}
