import React from 'react'
import classNames from 'classnames'

export default function Hidden(props: HiddenProps) {
  const { children, className = '', hide } = props
  const hiddenClass = 'hidden fixed top-0 left-0'

  return <div className={classNames(className, !!hide && hiddenClass)}>{children}</div>
}

interface HiddenProps {
  hide?: boolean
  className?: string
  children: React.ReactChild
}
