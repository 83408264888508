import { useCallback } from 'react'

export default function useIntercom() {
  const show = useCallback(() => {
    // @ts-expect-error Intercom is not typed
    window.Intercom('show')
  }, [])

  const boot = useCallback((email, user_id?: string) => {
    // @ts-expect-error Intercom is not typed
    window.Intercom('boot', { email, user_id })
  }, [])

  return { show, boot }
}
