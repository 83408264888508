import React from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller } from 'react-hook-form'
import classNames from 'classnames'

const styles = [
  'form-input',
  'w-full',
  'transition',
  'duration-150',
  'ease-in-out',
  'sm:text-sm',
  'sm:leading-5',
  'border',
  'border-gray-300',
  'placeholder-gray-500',
  'text-gray-900',
  'rounded-md',
  'focus:outline-none',
  'focus:shadow-outline-blue',
  'focus:border-blue-300',
  'focus:z-10',
]

export default function SelectInput<T>(props: StringInputProps<T>) {
  const { name, className, control, minDate, maxDate } = props

  return (
    <Controller
      control={control}
      name={name}
      className={classNames([...styles], className)}
      render={(props) => (
        <ReactDatePicker
          className={classNames([...styles], className)}
          placeholderText="Selectionner une date"
          onChange={(e) => props.onChange(e)}
          selected={props.value ? new Date(props.value) : props.value}
          locale="fr"
          dateFormat="dd/MM/yyyy"
          wrapperClassName="w-full"
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
    />
  )
}

interface StringInputProps<T> {
  name: string
  value: T
  className?: string
  control: any
  minDate?: Date
  maxDate?: Date
}
