import React from 'react'
import classNames from 'classnames'
import SelectInput from '../inputs/SelectInput'

export default function SelectField<T>(props: SelectFieldProps<T>) {
  const {
    label,
    required,
    error,
    register,
    name,
    options,
    disabled,
    readOnly,
    value,
  } = props

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700">
        {label} {required && '*'}
      </label>
      <div className="mt-1 rounded-md shadow-sm">
        <SelectInput
          register={register}
          name={name}
          value={value}
          options={options}
          className={classNames(
            'transition duration-150 ease-in-out',
            !!error && 'border-red-600 focus:border-red-300',
            (disabled || readOnly) && 'opacity-50 cursor-not-allowed',
          )}
          readOnly={readOnly}
        />
      </div>
      {!!error && (
        <p className="mt-1 text-sm text-red-600" id={`${name}-error`}>
          {error.message}
        </p>
      )}
    </>
  )
}

interface SelectFieldProps<T> {
  register: React.Ref<HTMLSelectElement>
  label: string
  required?: boolean
  name: string
  value: T
  options: { label: string; value: T; disabled?: boolean }[]
  error?: { message?: string }
  disabled?: boolean
  readOnly?: boolean
}
