import React from 'react'
import classNames from 'classnames'

const styles = [
  'form-textarea',
  'block',
  'w-full',
  'transition',
  'duration-150',
  'ease-in-out',
  'sm:text-sm',
  'sm:leading-5',
]

export default function TextInput(props: TextInputProps) {
  const { name, placeholder, className, register, rows, disabled = false, readOnly } = props

  return (
    <textarea
      ref={register}
      id={name}
      name={name}
      rows={rows}
      className={classNames([...styles], className)}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readOnly}
    />
  )
}

interface TextInputProps {
  register: React.Ref<HTMLTextAreaElement>
  placeholder?: string
  rows: number
  name: string
  className?: string
  disabled?: boolean
  readOnly?: boolean
}
