import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import TextField from '../../components/fields/TextField'
import StringField from '../../components/fields/StringField'
import { Maybe } from '../../../core/types'
import { ShopDetails } from '../../../core/models/shop/__generated__/ShopDetails'
import ShopImagesForm from './ShopImagesForm'

export type FormSchema = {
  name: string
  email: string
  phoneNumber?: Maybe<string>
  description: string
  addressStreet: string
  addressPostalCode: string
  addressCity: string
  schedule: string
  websiteUri?: Maybe<string>
  instagramUri?: Maybe<string>
  facebookUri?: Maybe<string>
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FormSchema = Yup.object().shape<FormSchema>({
  name: Yup.string().required('Ce champ est requis'),
  email: Yup.string().required('Ce champ est requis').email('Email non valide'),
  phoneNumber: Yup.string().notRequired(),
  description: Yup.string()
    .required('Ce champ est requis')
    .min(3, 'Minimum 3 caractères')
    .max(250, 'Maximum 250 caractères'),
  addressStreet: Yup.string().required('Ce champ est requis'),
  addressPostalCode: Yup.string().required('Ce champ est requis'),
  addressCity: Yup.string().required('Ce champ est requis'),
  schedule: Yup.string()
    .required('Ce champ est requis')
    .min(3, 'Minimum 3 caractères')
    .max(250, 'Maximum 250 caractères'),
  websiteUri: Yup.string().notRequired().url('URL non valide'),
  instagramUri: Yup.string().notRequired().url('URL non valide'),
  facebookUri: Yup.string().notRequired().url('URL non valide'),
})

export default function PublicInformationsForm(props: PublicInformationsFormProps) {
  const { shop, onUpdatePublicInformations, onUpdateShopImages } = props

  const initialValues = {
    name: shop?.name ?? '',
    email: shop?.email ?? '',
    phoneNumber: shop?.phoneNumber ?? '',
    description: shop?.description ?? '',
    addressStreet: shop?.address?.street ?? '',
    addressPostalCode: shop?.address?.postCode ?? '',
    addressCity: shop?.address?.city ?? '',
    schedule: shop?.schedule ?? '',
    websiteUri: shop?.websiteUri ?? '',
    instagramUri: shop?.instagramUri ?? '',
    facebookUri: shop?.facebookUri ?? '',
  }

  const { errors, register, reset, handleSubmit } = useForm<FormSchema>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: initialValues,
  })

  function onSubmitHandler(data: FormSchema) {
    onUpdatePublicInformations(data)
  }

  function onResetHandler(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    reset(initialValues)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Informations publiques
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Ces informations seront affichées en ligne sur la page de votre boutique.
              </p>
            </div>
            <div className="grid grid-cols-6 gap-6">
              {/* Nom */}
              <div className="col-span-6 sm:col-span-3">
                <StringField
                  register={register}
                  required={true}
                  label="Nom du magasin"
                  name="name"
                  error={errors.name}
                />
              </div>
              <div className="sm:col-span-3" />
              {/* Email */}
              <div className="col-span-6 sm:col-span-3">
                <StringField
                  register={register}
                  required={true}
                  label="Email"
                  name="email"
                  error={errors.email}
                />
              </div>
              {/* Phone */}
              <div className="col-span-6 sm:col-span-3">
                <StringField
                  register={register}
                  label="Numéro de téléphone"
                  name="phoneNumber"
                  error={errors.phoneNumber}
                />
              </div>
              {/* Description */}
              <div className="col-span-6">
                <TextField
                  register={register}
                  required={true}
                  label="Description"
                  name="description"
                  information="Trouvez les mots justes pour décrire votre magasin (250 caractères max.)."
                  rows={3}
                  error={errors.description}
                />
              </div>
              {/* Stree */}
              <div className="col-span-6">
                <TextField
                  register={register}
                  required={true}
                  label="Adresse"
                  name="addressStreet"
                  rows={2}
                  error={errors.addressStreet}
                />
              </div>
              {/* City */}
              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <StringField
                  register={register}
                  required={true}
                  label="Ville"
                  name="addressCity"
                  error={errors.addressCity}
                />
              </div>
              {/* Postcode */}
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <StringField
                  register={register}
                  required={true}
                  label="Code postal"
                  name="addressPostalCode"
                  error={errors.addressPostalCode}
                />
              </div>
              {/* Schedule */}
              <div className="col-span-6">
                <TextField
                  register={register}
                  required={true}
                  label="Horaires"
                  name="schedule"
                  placeholder={`Du lundi au vendredi, 10h30 à 19h\nLe samedi, 12h à 20h`}
                  rows={3}
                  error={errors.schedule}
                  information="Définissez avec précision vos horaires pour le click and collect"
                />
              </div>
              {/* Site internet */}
              <div className="sm:col-span-6">
                <StringField
                  register={register}
                  label="Site internet"
                  name="websiteUri"
                  placeholder="https://www.nom-de-boutique.com"
                  error={errors.websiteUri}
                />
              </div>
              {/* Instagram */}
              <div className="sm:col-span-6">
                <StringField
                  register={register}
                  label="Instagram"
                  name="instagramUri"
                  placeholder="https://www.instagram.com/nom-de-boutique"
                  error={errors.instagramUri}
                />
              </div>
              {/* Facebook */}
              <div className="sm:col-span-6">
                <StringField
                  register={register}
                  label="Facebook"
                  name="facebookUri"
                  placeholder="https://www.facebook.com/nom-de-boutique"
                  error={errors.facebookUri}
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:flex sm:items-center sm:justify-end">
            <div className="flex space-x-3">
              <span className="shadow-sm rounded-md">
                <button
                  onClick={onResetHandler}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                >
                  Annuler
                </button>
              </span>
              <button
                type="submit"
                className="bg-purple-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </form>
      <ShopImagesForm shop={shop} onUpdateShopImages={onUpdateShopImages} />
    </>
  )
}

interface PublicInformationsFormProps {
  shop: ShopDetails
  onUpdatePublicInformations: (data: FormSchema) => void
  onUpdateShopImages: (data: { uri?: string; file?: File }[]) => void
}
