import { gql } from '@apollo/client'
import { PRODUCT_DETAILS } from './fragments'

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      ...ProductDetails
    }
  }
  ${PRODUCT_DETAILS}
`

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: ID!, $input: UpdateProductInput!) {
    updateProduct(id: $id, input: $input) {
      ...ProductDetails
    }
  }
  ${PRODUCT_DETAILS}
`
