import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import className from 'classnames'
import { useSession } from '../../../core/contexts/session'
import { useShopsContext } from '../../../core/contexts/shops'
import { useAccount } from '../../../core/models/account/hooks'
import { AccountShop } from '../../../core/models/account/types'
import DropDown from '../../components/menus/DropDown'
import useIntercom from '../../../core/hooks/useIntercom'

export default function SideBarShop() {
  const history = useHistory()
  const location = useLocation()
  const intercom = useIntercom()
  const [open, setOpen] = useState(false)
  const { currentShop, switchTo } = useShopsContext()
  const { signOut } = useSession()
  const { account, loading } = useAccount({})

  if (loading || typeof account === 'undefined') {
    return null
  }

  const shops = account.shops.edges.map((shop) => shop.node)
  const shop = shops.find((shop) => shop.id === currentShop)

  return (
    <>
      <DropDown open={open} onClose={() => setOpen(false)}>
        <ShopDropDownContent
          email={account.email}
          shops={shops}
          currentShop={shop}
          onSwitchShop={(shopId: string) => {
            setOpen(false)
            switchTo(shopId)
            // TODO: this function should be in the authenticated router
            // so we would change it when we add routes
            if (location.pathname.includes('product')) {
              history.push('/products')
            } else {
              history.push('/orders')
            }
          }}
          onNavigate={(to) => {
            setOpen(false)
            history.push(to)
          }}
          onSignOut={signOut}
          onRequestSupport={() => {
            setOpen(false)
            intercom.show()
          }}
        />
      </DropDown>
      <div className="flex-shrink-0 flex border-t border-gray-200 p-4 cursor-pointer">
        <div className="flex-shrink-0 w-full group block" onClick={() => setOpen(!open)}>
          <div className="flex items-center">
            <div className="inline-block items-center justify-center h-9 w-9 rounded-full bg-gray-500 overflow-hidden">
              {shop?.logoUri ? (
                <img
                  className="inline-block h-9 w-9 rounded-full"
                  src={shop?.logoUri}
                  alt={shop?.name || ''}
                />
              ) : (
                <svg
                  className="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              )}
            </div>
            <div className="flex flex-col ml-3">
              <p className="text-sm font-medium leading-5 text-gray-900">
                {shop?.name || ''}
              </p>
              <span className="text-xs leading-4 font-medium text-gray-500 hover:text-purple-700 transition ease-in-out duration-150">
                {account.email}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function ShopDropDownContent(props: ShopDropDownContentProps) {
  const {
    email,
    shops,
    currentShop,
    onSwitchShop,
    onSignOut,
    onRequestSupport,
    onNavigate,
  } = props
  return (
    <div className="mt-2 w-60 rounded-mdbg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200">
      <div className="pb-2">
        <div className="px-4 py-3">
          <p className="text-sm">Connecté en tant que</p>
          <p className="text-sm font-medium text-gray-900 truncate">{email}</p>
        </div>
        <div className="divide-y divide-gray-100">
          {shops.map((shop) => (
            <div
              key={shop.id}
              className={className(
                'px-4 py-2 text-sm font-medium leading-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out flex justify-start items-center',
                shop.id === currentShop?.id &&
                  'pointer-events-none text-gray-900 hover:bg-gray-100',
                shop.id !== currentShop?.id && 'text-gray-400 cursor-pointer',
              )}
              onClick={() => onSwitchShop(shop.id)}
            >
              <span
                className={className(
                  'block h-2 w-2 rounded-full ring-2 ring-white bg-red-400 mr-2',
                  shop.id === currentShop?.id && 'bg-green-400',
                  shop.id !== currentShop?.id && 'bg-red-400',
                )}
              />
              <p>{shop.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div
        className="py-1"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div
          onClick={() => onNavigate('/shops/settings')}
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
          role="menuitem"
        >
          Paramètres
        </div>
        <div
          onClick={onRequestSupport}
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
          role="menuitem"
        >
          Support
        </div>
        <div
          onClick={onSignOut}
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
          role="menuitem"
        >
          Se déconnecter
        </div>
      </div>
    </div>
  )
}

interface ShopDropDownContentProps {
  email: string
  shops: AccountShop[]
  currentShop: AccountShop | undefined
  onSwitchShop: (shopId: string) => void
  onSignOut: () => void
  onRequestSupport: () => void
  onNavigate: (to: string) => void
}
