import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import CreateDiscount from '../../lib/containers/discounts/CreateDiscount'
import UpdateDiscount from '../../lib/containers/discounts/UpdateDiscount'

export default function DiscountPage() {
  const history = useHistory()
  const { slug } = useParams<{ slug: string }>()
  const toCreate = slug === 'create'

  return (
    <div className="pt-2 pb-6 md:py-6 mb-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <button
          // TODO: Fix in case of a copy pasted link this should go back to products/ page instead
          onClick={() => history.goBack()}
          className="font-medium text-purple-600 hover:text-purple-500 transition ease-in-out duration-150"
        >
          &larr; Retour
        </button>
      </div>
      {toCreate ? <CreateDiscount /> : <UpdateDiscount id={slug} />}
    </div>
  )
}
