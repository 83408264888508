import React from 'react'
import { useAlerts } from '../../../core/contexts/alerts'
import { useHistory } from 'react-router-dom'
import { useCreateDiscount } from '../../../core/models/discount/hooks'
import { CreateDiscountInput } from '../../../core/models/__generated__/globalTypes'
import Loading from '../../components/layout/Loading'
import DiscountForm from './DiscountForm'

export default function CreateDiscount() {
  const history = useHistory()
  const { pushSuccessAlert, pushErrorAlert } = useAlerts()
  const { createDiscount, discount, loading } = useCreateDiscount({
    onCompleted: (data) => {
      history.replace(`/discounts/${data.createDiscount.id}`)
      pushSuccessAlert('Le code promo est créé avec succès')
    },
    onError: (error) => {
      if (error.message === 'Code promo already exist') {
        pushErrorAlert('Un autre code produit existe avec le même code')
      } else {
        pushErrorAlert("Une erreur s'est produite, essayez d'enregistrer à nouveau")
      }
    },
  })

  function onSaveHandler(data: Omit<CreateDiscountInput, 'shopId'>) {
    createDiscount(data as CreateDiscountInput)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          {discount?.couponCode || 'Nouveau Code promotionnel'}
        </h1>
      </div>
      <DiscountForm discount={discount} onSave={onSaveHandler} />
    </>
  )
}
