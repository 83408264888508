import React, { useCallback } from 'react'
import {
  Editable,
  useSlate,
  Slate,
  RenderElementProps,
  RenderLeafProps,
  ReactEditor,
} from 'slate-react'
import { Editor, Transforms, Element as SlateElement, Node } from 'slate'
import classNames from 'classnames'

const icons = {
  bold: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="black"
      width="18px"
      height="18px"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z" />
    </svg>
  ),
  italic: (
    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z" />
    </svg>
  ),
  underline: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="black"
      width="18px"
      height="18px"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2H5z" />
    </svg>
  ),
  title: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="black"
      width="18px"
      height="18px"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14h-2V9h-2V7h4v10z" />
    </svg>
  ),
  subtitle: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="black"
      width="18px"
      height="18px"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4 8c0 1.11-.9 2-2 2h-2v2h4v2H9v-4c0-1.11.9-2 2-2h2V9H9V7h4c1.1 0 2 .89 2 2v2z" />
    </svg>
  ),
}

export default function RichTextEditor(props: RichTextEditorProps) {
  const { editor, value, placeholder, onChange } = props
  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])

  return (
    <Slate editor={editor} value={value} onChange={onChange}>
      <ToolBar>
        <MarkButton format="bold" icon={icons.bold} />
        <MarkButton format="italic" icon={icons.italic} />
        <MarkButton format="underline" icon={icons.underline} />
        <BlockButton format="h1" icon={icons.title} />
        <BlockButton format="h2" icon={icons.subtitle} />
      </ToolBar>
      <div className="border rounded-sm p-2">
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          spellCheck
          autoFocus
        />
      </div>
    </Slate>
  )
}

function toggleBlock(editor: Editor, format: string) {
  const isActive = isBlockActive(editor, format)

  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : format,
  }
  Transforms.setNodes(editor, newProperties)
}

function toggleMark(editor: Editor, format: string) {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

function isBlockActive(editor: Editor, format: string) {
  // @ts-expect-error since i have no idea why
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

function isMarkActive(editor: Editor, format: string) {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

function Element({ attributes, children, element }: RenderElementProps) {
  switch (element.type) {
    case 'h1':
      return (
        <h1 className="text-3xl leading-6 font-bold" {...attributes}>
          {children}
        </h1>
      )
    case 'h2':
      return (
        <h2 className="text-2xl leading-6 font-bold" {...attributes}>
          {children}
        </h2>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}

function Leaf({ attributes, children, leaf }: RenderLeafProps) {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

function ToolBar(props: React.PropsWithChildren<{}>) {
  return (
    <span className="relative z-0 inline-flex shadow-md rounded-md">{props.children}</span>
  )
}

function BlockButton({ format, icon }: any) {
  const editor = useSlate()
  const active = isBlockActive(editor, format)

  return (
    <button
      className={classNames(
        'relative inline-flex items-center px-2 py-2 focus:outline-none focus:ring-1 focus:z-10',
        !active && 'hover:bg-gray-50',
        active && 'bg-gray-300 ',
      )}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {icon}
    </button>
  )
}

function MarkButton({ format, icon }: any) {
  const editor = useSlate()
  const active = isMarkActive(editor, format)

  return (
    <button
      className={classNames(
        'relative inline-flex items-center px-2 py-2 focus:outline-none focus:ring-1 focus:z-10',
        !active && 'hover:bg-gray-50',
        active && 'bg-gray-300 ',
      )}
      type="button"
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {icon}
    </button>
  )
}

interface RichTextEditorProps {
  editor: ReactEditor
  value: Node[]
  onChange: (value: Node[]) => void
  placeholder?: string
}
