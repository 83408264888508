import React from 'react'
import { useAlerts } from '../../../core/contexts/alerts'
import { useHistory } from 'react-router-dom'
import { useCreateProduct } from '../../../core/models/product/hooks'
import {
  CreateProductInput,
  UpdateProductInput,
} from '../../../core/models/__generated__/globalTypes'
import Loading from '../../components/layout/Loading'
import ProductForm from './ProductForm'

export default function CreateProduct() {
  const history = useHistory()
  const { pushSuccessAlert, pushErrorAlert } = useAlerts()
  const { createProduct, product, loading } = useCreateProduct({
    onCompleted: (data) => {
      history.replace(`/products/${data.createProduct.id}`)
      pushSuccessAlert('Le produit est créé avec succès')
    },
    onError: (error) => {
      if (error.message === 'Product already exist') {
        pushErrorAlert('Un autre produit existe avec le même identifiant')
      } else {
        pushErrorAlert("Une erreur s'est produite, essayez d'enregistrer à nouveau")
      }
    },
  })

  function onSaveHandler(data: Omit<CreateProductInput | UpdateProductInput, 'shopId'>) {
    createProduct(data as CreateProductInput)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          {product?.name || 'Nouveau produit'}
        </h1>
      </div>
      <ProductForm product={product} onSave={onSaveHandler} />
    </>
  )
}
