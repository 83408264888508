import { gql } from '@apollo/client'

export const SHOP_DETAILS = gql`
  fragment ShopDetails on Shop {
    id
    createdAt
    updatedAt
    name
    address {
      street
      city
      postCode
    }
    images {
      uri
    }
    homeDelivery {
      price
      minDaysDelay
      maxDaysDelay
      active
    }
    phoneNumber
    instagramUri
    facebookUri
    websiteUri
    logoUri
    email
    schedule
    description
    termsOfSale
    active
    paymentActive
    originId
    franchise
    orderSettings {
      allowOutOfStockProducts
    }
  }
`
