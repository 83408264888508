import { useCallback } from 'react'
import { useQuery, useMutation, MutationHookOptions } from '@apollo/client'
import { GET_DISCOUNT, GET_DISCOUNTS } from './queries'
import { CREATE_DISCOUNT, UPDATE_DISCOUNT } from './mutations'
import { GetDiscounts, GetDiscountsVariables } from './__generated__/GetDiscounts'
import { GetDiscount, GetDiscountVariables } from './__generated__/GetDiscount'
import { useShopsContext } from '../../contexts/shops'
import usePagination from '../../hooks/usePagination'
import { CreateDiscountVariables, CreateDiscount } from './__generated__/CreateDiscount'
import { UpdateDiscountVariables, UpdateDiscount } from './__generated__/UpdateDiscount'
import { CreateDiscountInput } from '../__generated__/globalTypes'

export function useDiscounts(variables: Omit<GetDiscountsVariables, 'shopId'>) {
  const { currentShop } = useShopsContext()
  const { loading, data, error, fetchMore } = usePagination<
    GetDiscounts,
    GetDiscountsVariables
  >(GET_DISCOUNTS, 'discounts', {
    variables: { ...variables, shopId: currentShop },
    fetchPolicy: 'cache-and-network',
  })

  return { loading, error, discounts: data?.discounts, fetchMore }
}

export function useDiscount(variables: GetDiscountVariables) {
  const { loading, data, error } = useQuery<GetDiscount, GetDiscountVariables>(
    GET_DISCOUNT,
    {
      variables,
      fetchPolicy: 'cache-and-network',
    },
  )

  return { loading, error, discount: data?.discount }
}

export function useCreateDiscount(
  options?: MutationHookOptions<CreateDiscount, CreateDiscountVariables>,
) {
  const { currentShop } = useShopsContext()
  const [mutation, { data, loading }] = useMutation<
    CreateDiscount,
    CreateDiscountVariables
  >(CREATE_DISCOUNT, options)

  const createDiscount = useCallback(
    async (variables: Omit<CreateDiscountInput, 'shopId'>) => {
      await mutation({
        variables: { input: { ...variables, shopId: currentShop } },
      })
    },
    [mutation, currentShop],
  )

  return { createDiscount, discount: data?.createDiscount, loading }
}

export function useUpdateDiscount(
  options?: MutationHookOptions<UpdateDiscount, UpdateDiscountVariables>,
) {
  const [mutation, { data, loading }] = useMutation<
    UpdateDiscount,
    UpdateDiscountVariables
  >(UPDATE_DISCOUNT, options)

  const updateDiscount = useCallback(
    async (variables: UpdateDiscountVariables) => {
      await mutation({ variables })
    },
    [mutation],
  )

  return { updateDiscount, discount: data?.updateDiscount, loading }
}
