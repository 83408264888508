import { gql } from '@apollo/client'

export const ORDER_DETAILS = gql`
  fragment OrderDetails on Order {
    id
    createdAt
    updatedAt
    archivedAt
    payedAt
    validatedAt
    preparedAt
    canceledAt
    refundedAt
    deliveredAt
    name
    status
    customer {
      name
      email
    }
    billing {
      name
      address {
        line1
        line2
        city
        postalCode
      }
    }
    shipping {
      name
      address {
        line1
        line2
        city
        postalCode
      }
    }
    delivery {
      option
      fees
    }
    products {
      reference
      name
      variantName
      kind
      color
      imageUri
      producer
      country
      region
      family
      wineType
      beerType
      designation
      price
      tax
      quantity
      totalAmountIncludingTax
      crossedPrice
    }
    totalAmountExcludingTaxes
    totalAmountIncludingTaxes
    totalAmountIncludingDiscount
    discount {
      unit
      couponCode
      value
    }
  }
`

export const ORDER_CONNECTION_DETAILS = gql`
  fragment OrderConnectionDetails on OrderConnection {
    edges {
      cursor
      node {
        ...OrderDetails
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    totalCount
  }
  ${ORDER_DETAILS}
`
