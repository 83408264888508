import React, { useState } from 'react'
import { ShopDetails } from '../../../core/models/shop/__generated__/ShopDetails'
import OrderSettingsChangeStateModal from './OrderSettingsChangeStateModal'
import classNames from 'classnames'

export default function OrderSettingsForm(props: OrderSettingsFormProps) {
  const { shop, onUpdateShopOrderSettings } = props
  const [open, setOpen] = useState(false)

  const active = shop.orderSettings.allowOutOfStockProducts

  function handleClickButtonSwitch() {
    setOpen(true)
  }

  function handleValidateChange() {
    onUpdateShopOrderSettings({ allowOutOfStockProducts: !active })
    setOpen(false)
  }

  return (
    <>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Paramètres de commande
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Vous pouvez autoriser ou non les internautes à commander sur votre boutique
              des produits en rupture de stock.
            </p>
          </div>
        </div>
        <div className="bg-white py-4 px-4 space-y-6 sm:p-6">
          <div className="flex items-center justify-between">
            <span className="flex-grow flex flex-col" id="availability-label">
              <span className="text-sm font-medium text-gray-900">
                Autoriser les commandes avec des produits en rupture de stock
              </span>
              <span className="text-sm text-gray-500">
                {active
                  ? 'Commande de produits en rupture de stock activé '
                  : 'Commande de produits en rupture de stock désactivé'}
              </span>
            </span>
            <button
              type="button"
              className={classNames(
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                active ? 'bg-green-400' : 'bg-gray-200',
              )}
              aria-pressed="false"
              aria-labelledby="availability-label"
              onClick={handleClickButtonSwitch}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={classNames(
                  'pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200 bg-white',
                  active ? 'translate-x-full' : 'translate-x-0',
                )}
              ></span>
            </button>
          </div>
        </div>
      </div>
      <OrderSettingsChangeStateModal
        open={open}
        active={active}
        onCancel={() => setOpen(false)}
        onValidate={handleValidateChange}
      />
    </>
  )
}

interface OrderSettingsFormProps {
  shop: ShopDetails
  onUpdateShopOrderSettings: (data: { allowOutOfStockProducts: boolean }) => void
}
