import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

export default function RouteLayout(props: RouteLayoutProps) {
  const { layout: Layout, component: Component, ...rest } = props

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  )
}

type RouteLayoutProps = {
  component: React.ElementType
  layout: React.ElementType
} & RouteProps
