const PRODUCTION = process.env.NODE_ENV === 'production'
const APP_AUTH_TOKEN = 'APP-AUTH-TOKEN'
const CURRENT_SHOP_ID = 'CURRENT-SHOP-ID'

let API_BASE_URI: string | null = 'http://localhost:4000'
let SENTRY_DNS_URI: string | null = null
let APP_BASE_URI: string | null = 'http://localhost:5000'

if (PRODUCTION) {
  API_BASE_URI = process.env.REACT_APP_POTICO_API_URI ?? null
  SENTRY_DNS_URI = process.env.REACT_APP_SENTRY_DNS_URI ?? null
  APP_BASE_URI = process.env.REACT_APP_APP_BASE_URI ?? null
}

export {
  PRODUCTION,
  SENTRY_DNS_URI,
  API_BASE_URI,
  APP_BASE_URI,
  APP_AUTH_TOKEN,
  CURRENT_SHOP_ID,
}
