import { gql } from '@apollo/client'
import { ORDER_DETAILS, ORDER_CONNECTION_DETAILS } from './fragments'

export const GET_ORDERS = gql`
  query GetOrders(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $shopId: String!
    $statusIn: [OrderStatus!]!
    $name: String
  ) {
    orders(
      first: $first
      last: $last
      before: $before
      after: $after
      where: { shopId: { _in: [$shopId] }, status: { _in: $statusIn }, name: $name }
    ) {
      ...OrderConnectionDetails
    }
  }
  ${ORDER_CONNECTION_DETAILS}
`

export const GET_ORDER = gql`
  query GetOrder($id: ID!) {
    order(id: $id) {
      ...OrderDetails
    }
  }
  ${ORDER_DETAILS}
`
